import React, { useState, useEffect, useContext } from "react";
import "react-dates/lib/css/_datepicker.css";
import { motion } from "framer-motion";
import { AddnewIcon, NoDataIcon } from "../assets/icons/hiking";
import { useNavigation } from './NavigationContext';
import { useRoutes, navigate } from "hookrouter";

import { useNotifications } from './NotificationContext';
import { ResponseContext } from "./ResponseContext";

import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';

import LoadingButton from '@mui/lab/LoadingButton';

import { SearchContext } from './searchContext';

export function Transactions() {
  const { showNotification } = useNotifications();

  const [selectedCustomerNonce, setSelectedCustomerNonce] = useState(null);
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(0);

  const { searchTerm } = useContext(SearchContext);

  useEffect(() => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "customer";

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }

    setIsLoading(true);
    setMessage("");
    const url =
      protocolpluspath() +
      "admin/getData.php?eid=" +
      eid +
      "&type=" +
      type +
      "&search=" +
      searchTerm +
      "&nonce=" +
      nonce;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data.success &&
          data.data.success &&
          data.data.count > 0 &&
          data.data.response !== "NO"
        ) {
          const reversedCustomers = data.data.response.reverse();
          setData(reversedCustomers);
          setMessage("");
        } else {
          setData([]);
          setMessage("No Data");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMessage("No Data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [triggerFetch, searchTerm]);



  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(null);


  const [users, setUsers] = useState([]);
  const [loadingWooCustomers, setLoadingWooCustomers] = useState(false);
  const [noWooCustomers, setNoWooCustomers] = useState("");

  useEffect(() => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "user";


    console.log('Fetching with searchTerm:', searchTerm);

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }

    setLoadingWooCustomers(true);
    setNoWooCustomers('')
    const url =
      protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&search=" + searchTerm + "&nonce=" + nonce;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUsers(data.data.response);
        } else {
          setNoWooCustomers("No Data");
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setNoWooCustomers('No Data');
      })
      .finally(() => {
        setLoadingWooCustomers(false);
      });
  }, [searchTerm]);


  //WooCommerce Customers

  const [showWooCustomerDetails, setShowWooCustomerDetails] = useState(false);
  const [wooCustomerDetails, setWooCustomerDetails] = useState(null);

  const fetchWooCustomerDetails = (wooCustomerNonce) => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "user";
    const id = wooCustomerNonce;

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }
    if (!eid || !wooCustomerNonce) {
      console.error('Error: Missing required values in localStorage');
      return;
    }
    const url =
      protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=user" + "&id=" + id + "&nonce=" + nonce;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data.response) {
          setShowWooCustomerDetails(true);
          setWooCustomerDetails(data.data.response[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  function WooCustomersPopup({ wooCustomerDetails, onClose }) {
    if (!wooCustomerDetails || !wooCustomerDetails.user) {
      return null;
    }
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h1 className="text-lg font-semibold mb-4">Customer Details:</h1>
          <p>Username: {wooCustomerDetails.user.username}</p>
          <p>First Name: {wooCustomerDetails.user.first_name}</p>
          <p>Last Name: {wooCustomerDetails.user.last_name}</p>
          <p>Email: {wooCustomerDetails.user.email}</p>
          <p>Created Date: {wooCustomerDetails.user.created_date}</p>

          <div className="flex justify-end mt-6">
            <button
              className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700"
              onClick={onClose}
            >
              Close
            </button>
          </div>

        </div>
      </div>
    );
  }


  //NTT Customer details
  const fetchCustomersDetails = (customerNonce, forEdit = false) => {
    const protocolpluspath = () =>
      window.location.protocol + "//" + window.location.host + "/";

    const eid = localStorage.getItem("default");
    const url =
      protocolpluspath() +
      "admin/getData.php?eid=" +
      eid +
      "&type=customer&id=" +
      encodeURIComponent(customerNonce);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response:", data);
        if (data.success && data.data.response) {
          if (forEdit) {
            setSelectedCustomerForEdit(data.data.response[0]);
            setShowEditPopup(true);
          } else {
            setSelectedCustomerDetails(data.data.response[0]);
          }
        }
      })
      .catch((error) => {
        console.error("Error processing data:", error);
      });
  };

  //  delete NTT Customer

  const deleteCustomer = (nonce) => {
    const eid = localStorage.getItem("default");

    const payload = {
      eid,
      nonce: nonce,
      endpoint: "v1/customer/delete",
    };

    fetch("/admin/postData.php", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response === "OK") {
          const Notificationpayload = {
            response: true,
            subject: "User",
            operation: "Deleted",
            context: "",
          };
          console.log('Closing popup');
          setOpen(false);
          showNotification(Notificationpayload);

          setTriggerFetch(prev => prev + 1);
        } else {
          const Notificationpayload = {
            response: false,
            subject: "User",
            operation: "Deleting",
            context: "",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "User",
          operation: "Deleting",
          context: "",
        };
        showNotification(Notificationpayload);
      });
  };

  function CustomersPopup({ customer, onClose }) {
    const customerDetails = customer.customer;
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-lg font-semibold mb-4">
            View Details: {customerDetails.customer_number}
          </h2>
          {Object.keys(customerDetails).map((key) => {
            // skip keys don't want to show
            if (key === "nonce" || key === "customer_name") {
              return null;
            }
            return (
              <p key={key}>
                {key
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                : {customerDetails[key]}
              </p>
            );
          })}
          <div className="flex justify-end mt-6">
            <button
              className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  const DeletePopup = ({ onClose, onDelete }) => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-bold mb-4">Delete Customer</h2>
        <p>You are about to delete this customer, are you sure?</p>
        <div className="flex justify-center gap-4 mt-4">
          <button
            onClick={onDelete}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Delete
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  const [showPopup, setShowPopup] = useState(false);

  const handleDeleteClick = (nonce) => {
    setSelectedCustomerNonce(nonce);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmDelete = () => {
    if (selectedCustomerNonce) {
      deleteCustomer(selectedCustomerNonce);
      setShowPopup(false);
    }
  };

  const updateCustomer = (updatedCustomerData) => {
    const eid = localStorage.getItem("default");
    const payload = {
      nonce: selectedCustomerForEdit.customer.nonce,
      first_name: updatedCustomerData.first_name,
      last_name: updatedCustomerData.last_name,
      company: updatedCustomerData.company,
      date_of_birth: updatedCustomerData.date_of_birth,
      customer_number: updatedCustomerData.customer_number,
      phone: updatedCustomerData.phone,
      email: updatedCustomerData.email,

      mailing_address: updatedCustomerData.mailing_address,
      mailing_street1: updatedCustomerData.mailing_street1,
      mailing_street2: updatedCustomerData.mailing_street2,
      mailing_city: updatedCustomerData.mailing_city,
      mailing_state: updatedCustomerData.mailing_state,
      mailing_zip: updatedCustomerData.mailing_zip,
      service_address: updatedCustomerData.service_address,
      service_street1: updatedCustomerData.service_street1,
      service_street2: updatedCustomerData.service_street2,
      service_city: updatedCustomerData.service_city,
      service_state: updatedCustomerData.service_state,
      service_zip: updatedCustomerData.service_zip,

      catalog_1: "Root",
      catalog_2: "Select",
      catalog_3: "Select",
      catalog_4: "Select",
      catalog_5: "Select",

      cardholdername: updatedCustomerData.cardholdername,
      street: updatedCustomerData.street,
      city: updatedCustomerData.city,
      state: updatedCustomerData.state,
      zip: updatedCustomerData.zip,
      amount: updatedCustomerData.amount,
      tax: updatedCustomerData.tax,

      eid: eid,
      endpoint: "v1/customer/update",
    };

    fetch("/admin/postData.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {
          const Notificationpayload = {
            response: true,
            subject: "Customer",
            operation: "Updated",
            context: `Customer has been Updated`,
          };
          showNotification(Notificationpayload);

          // setShowUpdateCustomerPopup(false);

          setShowEditPopup(false);
          setTriggerFetch(prev => prev + 1);

        } else {
          const Notificationpayload = {
            response: false,
            subject: "Customer",
            operation: "Updating",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Customer",
          operation: "Updating",
        };

        showNotification(Notificationpayload);
      });

  };

  // edit NTT Customer popup
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCustomerForEdit, setSelectedCustomerForEdit] = useState(null);


  function EditCustomerPopup({ customer, onClose }) {
    const [editedCustomer, setEditedCustomer] = useState(customer.customer);
    const [activeTab, setActiveTab] = React.useState("contact");


    // update customer confirmation popup
    const [showUpdateCustomerPopup, setShowUpdateCustomerPopup] = useState(false);


    const updateCustomerPopup = () => (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg text-center">
          <h2 className="text-xl font-bold mb-4">Update Customer</h2>
          <p>You are about to Update this customer, are you sure?</p>
          <div className="flex justify-center gap-4 mt-4">
            <button
              onClick={cancelUpdate}
              className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
            >
              Cancel
            </button>
            <button
              onClick={confirmUpdate}
              className="bg-[#4C7021] hover:bg-[#3a5317] text-white font-bold py-2 px-4 rounded"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );

    const handleInputChange = (e) => {
      setEditedCustomer({ ...editedCustomer, [e.target.name]: e.target.value });
    };

    const handleUpdateClick = () => {
      setShowUpdateCustomerPopup(true);
    };

    const confirmUpdate = () => {
      const updatedCustomerData = {
        ...editedCustomer,
        nonce: customer?.customer.nonce,
      };

      updateCustomer(updatedCustomerData);
      setShowUpdateCustomerPopup(false);
    };

    const cancelUpdate = () => {
      setShowUpdateCustomerPopup(false);
    };


    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-start pt-10">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto">
          <h2 className="text-lg font-semibold mb-4">
            Edit Customer: {editedCustomer.customer_number}
          </h2>

          <div className="flex justify-between items-center mb-2">
            <div className="flex-1 mr-2">
              <label className="text-sm font-semibold">First Name:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="first_name"
                value={editedCustomer.first_name}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex-1 ml-2">
              <label className="text-sm font-semibold">Last Name:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="last_name"
                value={editedCustomer.last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mb-2">
            <div className="flex-1 mr-2">
              <label className="text-sm font-semibold">Company:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="company"
                value={editedCustomer.company}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex-1 ml-2">
              <label className="text-sm font-semibold">Last Updated:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="date_of_birth"
                value={editedCustomer.date_of_birth}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <label className="text-sm font-semibold">Customer #:</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            name="customer_number"
            value={editedCustomer.customer_number}
            onChange={handleInputChange}
          />

          <div className="flex justify-center mt-4 border-b border-gray-300">
            <button
              className={`py-2 px-4 ${activeTab === "contact" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("contact")}
            >
              Contact
            </button>
            <button
              className={`py-2 px-4 ml-2 ${activeTab === "mailing" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("mailing")}
            >
              Mailing Address
            </button>

            <button
              className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("service-address")}
            >
              Service Address
            </button>

          </div>

          <div className="mt-4">
            {activeTab === "contact" && (
              <div>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex-1 mr-2">
                    <label className="text-sm font-semibold">Phone #:</label>
                    <input
                      placeholder="---"
                      className="w-full input-style"
                      type="text"
                      name="phone"
                      value={editedCustomer.phone}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="flex-1 ml-2">
                    <label className="text-sm font-semibold">Email:</label>
                    <input
                      placeholder="---"
                      className="w-full input-style"
                      type="text"
                      name="email"
                      value={editedCustomer.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === "mailing" && (
              <div>
                <label className="text-sm font-semibold">
                  Mailing Address Nickname:
                </label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="mailing_address"
                  value={editedCustomer.mailing_address}
                  onChange={handleInputChange}
                />

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">Street 1:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_street1"
                        value={editedCustomer.mailing_street1}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">Street 2:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_street2"
                        value={editedCustomer.mailing_street2}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">City:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_city"
                        value={editedCustomer.mailing_city}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">State:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_state"
                        value={editedCustomer.mailing_state}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <label className="text-sm font-semibold">Zip:</label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="mailing_zip"
                  value={editedCustomer.mailing_zip}
                  onChange={handleInputChange}
                />
              </div>
            )}
            {activeTab === "service-address" && (
              <div>
                <label className="text-sm font-semibold">
                  Mailing Adress Nickname:
                </label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="service_address"
                  value={editedCustomer.service_address}
                  onChange={handleInputChange}
                />

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">Street 1:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_street1"
                        value={editedCustomer.service_street1}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">Street 2:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_street2"
                        value={editedCustomer.service_street2}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">City:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_city"
                        value={editedCustomer.service_city}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">State:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_state"
                        value={editedCustomer.service_state}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <label className="text-sm font-semibold">Zip:</label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="service_zip"
                  value={editedCustomer.service_zip}
                  onChange={handleInputChange}
                />
              </div>
            )}

          </div>

          <div className="flex justify-end mt-6">
            <button
              className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="py-2 px-4  text-white rounded bg-[#4C7021] hover:bg-[#3a5317]"
              onClick={handleUpdateClick}
            >
              Update
            </button>
            {showUpdateCustomerPopup && updateCustomerPopup()}
          </div>

        </div>
      </div>
    );
  }

  const handleCustomerClick = (customerNonce) => {
    fetchCustomersDetails(customerNonce);
  };

  const handleEditClick = (customerNonce) => {
    fetchCustomersDetails(customerNonce, true);
  };



  const [activeTab, setActiveTab] = React.useState("contact");
  const { responseMessage, setResponseMessage } = useContext(ResponseContext);
  const { errorMessage, setErrorMessage } = useContext(ResponseContext);


  const [nttCustomers, setNttCustomers] = useState(false);
  const [wooCustomers, setWooCustomers] = useState(true);
  const [createCustomerForm, setCreateCustomerForm] = useState(false);

  function createCustomerModalButton() {
    setWooCustomerDetails(false);
    setNttCustomers(false);
    setCreateCustomerForm(true);
  }

  function customersLocalButton() {
    setWooCustomers(false);
    setNttCustomers(true);
  }

  function handleCancel() {
    setNttCustomers(false);
    setWooCustomers(true);
  }

  function cancelCreateCustomerForm() {
    setCreateCustomerForm(false);
    setNttCustomers(true);

    setFirstName('');
    setLastName('');
    setCompany('');
    setPhone('');
    setEmail('');

    setMailing_address('');
    setMailing_street1('');
    setMailing_street2('');
    setMailing_city('');
    setMailing_state('');
    setMailing_zip('');

    setService_address('');
    setService_street1('');
    setService_street2('');
    setService_city('');
    setService_state('');
    setService_zip('');
  }

  const [open, setOpen] = React.useState(false);
  const [currentNonce, setCurrentNonce] = useState(null);

  const handleOpenModal = (nonce) => {
    setCurrentNonce(nonce);
    setOpen(true);
  };


  // CREATE A CUSTOMER
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [mailing_address, setMailing_address] = useState('');
  const [mailing_street1, setMailing_street1] = useState('');
  const [mailing_street2, setMailing_street2] = useState('');
  const [mailing_city, setMailing_city] = useState('');
  const [mailing_state, setMailing_state] = useState('');
  const [mailing_zip, setMailing_zip] = useState('');

  const [service_address, setService_address] = useState('');
  const [service_street1, setService_street1] = useState('');
  const [service_street2, setService_street2] = useState('');
  const [service_city, setService_city] = useState('');
  const [service_state, setService_state] = useState('');
  const [service_zip, setService_zip] = useState('');


  const handleCreateCustomerClick = () => {
    createCustomer();
  };


  const eid = localStorage.getItem('default');

  const createCustomer = (mailingAddress, serviceAddress) => {
    const payload = {

      first_name: firstName,
      last_name: lastName,
      company: company,
      phone: phone,
      email: email,

      mailing_address: mailingAddress?.line1 || '',
      mailing_street1: mailingAddress?.line1 || '',
      mailing_street2: mailingAddress?.line2 || '',
      mailing_city: mailingAddress?.city || '',
      mailing_state: mailingAddress?.region || '',
      mailing_zip: mailingAddress?.postalCode || '',

      service_address: serviceAddress?.line1 || '',
      service_street1: serviceAddress?.line1 || '',
      service_street2: serviceAddress?.line2 || '',
      service_city: serviceAddress?.city || '',
      service_state: serviceAddress?.region || '',
      service_zip: serviceAddress?.postalCode || '',



      eid: eid,
      endpoint: "v1/customer/add",
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {
          const Notificationpayload = {
            response: true,
            subject: "Customer",
            operation: "Created",
            context: `User "${firstName} ${lastName}" has been created`,
          };
          showNotification(Notificationpayload);

          setFirstName('');
          setLastName('');
          setCompany('');
          setPhone('');
          setEmail('');

          setMailing_address('');
          setMailing_street1('');
          setMailing_street2('');
          setMailing_city('');
          setMailing_state('');
          setMailing_zip('');

          setService_address('');
          setService_street1('');
          setService_street2('');
          setService_city('');
          setService_state('');
          setService_zip('');


          cancelCreateCustomerForm();// go back to customers when success
          setTriggerFetch(prev => prev + 1);

        } else {
          const Notificationpayload = {
            response: false,
            subject: "Customer",
            operation: "Creating",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Customer",
          operation: "Creating",
        };

        showNotification(Notificationpayload);
      });

  }

  const allFieldsFilled = () => {
    return (
      firstName &&
      lastName &&
      company &&
      phone &&
      email &&
      // mailing_address &&
      mailing_street1 &&
      // mailing_street2 &&
      mailing_city &&
      mailing_state &&
      mailing_zip
    );
  };

  // Create a sale with a specific NTT customer
  const { setOrderClicked, setCustomerNttNonce } = useNavigation();

  function handleOrderClick(nonce) {
    setOrderClicked(true);
    setCustomerNttNonce(nonce);
    navigate('/app/orders');
  }


  // stepper to verify Mailing address
  const [createCustomerLoading, setCreateCustomerLoading] = useState(false);

  const mailingAndServiceAddress = () => {
    const eid = localStorage.getItem('default');

    const payload = {

      mailing_line1: mailing_street1,
      mailing_line2: mailing_street2,
      mailing_city: mailing_city,
      mailing_region: mailing_state,
      mailing_postalCode: mailing_zip,
      mailing_country: "US",

      service_line1: service_street1,
      service_line2: service_street2,
      service_city: service_city,
      service_region: service_state,
      service_postalCode: service_zip,
      service_country: "US",


      eid: eid,
      endpoint: 'v1/tax/avalara/resolve/mailing/and/service/address',
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    setCreateCustomerLoading(true);

    fetch('/admin/postData.php', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {

          const mailingMessages = data?.response?.mailing_result?.messages;
          const serviceMessages = data?.response?.service_result?.messages;

          const hasMailingError = mailingMessages && mailingMessages.length > 0 && mailingMessages[0].severity === "Error";
          const hasServiceError = serviceMessages && serviceMessages.length > 0 && serviceMessages[0].severity === "Error";

          if (hasMailingError || hasServiceError) {
            let Notificationpayload;
            if (hasMailingError) {
              const errorMessages = mailingMessages[0];
              Notificationpayload = {
                response: false,
                subject: "Mailing Address",
                operation: "Finding",
                context: "Address is incomplete or invalid",
                action: "Details",
                summary: errorMessages.summary,
                details: errorMessages.details,
              };
              setActiveTab("mailing");
            } else if (hasServiceError) {
              const errorMessages = serviceMessages[0];
              Notificationpayload = {
                response: false,
                subject: "Service Address",
                operation: "Finding",
                context: "Address is incomplete or invalid",
                action: "Details",
                summary: errorMessages.summary,
                details: errorMessages.details,
              };
              setActiveTab("service-address");
            }
            showNotification(Notificationpayload);
          } else {
            // success!

            const validatedMailingAddresses = data.response.mailing_result?.validatedAddresses || [];
            const validatedServiceAddresses = data.response.service_result?.validatedAddresses || [];

            console.log(validatedMailingAddresses, validatedServiceAddresses);

            createCustomer(validatedMailingAddresses[0], validatedServiceAddresses[0]);
          }
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Address",
          operation: "Finding",
          context: "Mailing Address is incomplete or invalid",
          action: "Details",
        };
        setActiveTab("mailing");
        showNotification(Notificationpayload);
      })
      .finally(() => {
        setCreateCustomerLoading(false);
      });

  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-screen"
      style={{
        backgroundImage: 'url("https://5ca93112550f8dcf11d2-5a9e76d0467760b267bf96203a242b5e.ssl.cf1.rackcdn.com/MM_BG.png")',
        backgroundSize: 'cover',
      }}
    >
      {wooCustomers && (
        <div>
          <div className="flex justify-between items-center p-4 pl-6">
            <div>
              <h1 className="text-2xl font-bold">Customers (WooCommerce)</h1>
              <p>View customers currently found in WooCommerce.</p>
            </div>

            <div className="flex items-center space-x-4 m-2 ">
              <button className="add-new-token btn" onClick={customersLocalButton}>
                Customers (Local)
              </button>
            </div>
          </div>


          <div className="flex-grow overflow-auto bg-white rounded-lg shadow-2xl m-6">
            <table className="table-auto w-full">
              <thead className="bg-gray-100">
                <tr className="text-left">
                  <th className="px-6 py-4">#</th>
                  <th className="px-6 py-4">First Name</th>
                  <th className="px-6 py-4">Last Name</th>
                  <th className="px-6 py-4">Email</th>
                  <th className="px-6 py-4">Action</th>
                </tr>
              </thead>

              <tbody>
                {users &&
                  users.map((item, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td
                        onClick={() => fetchWooCustomerDetails(item.user.nonce)}
                        className="px-6 py-4 text-[#4C7021] hover:underline cursor-pointer hover:text-[#4C7021]">
                        {item.id}
                      </td>

                      <td className="px-6 py-4 break-words">{item.user.first_name ?? '--'}</td>
                      <td className="px-6 py-4 break-words">{item.user.last_name ?? '--'}</td>
                      <td className="px-6 py-4 break-words">{item.user.email ?? '--'}</td>
                      <td className="px-6 py-4">
                        <button
                          className="bg-yellowsea hover:bg-yellowsea font-semibold text-white py-2 px-4 rounded cursor-pointer"
                          onClick={() => { handleOrderClick(item.user.nonce) }}
                        >
                          Sale
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {loadingWooCustomers && (
              <div className="flex flex-col items-center justify-center h-64">
                <div>Loading...</div>
              </div>
            )}
            {showWooCustomerDetails && wooCustomerDetails && (
              <WooCustomersPopup
                wooCustomerDetails={wooCustomerDetails}
                onClose={() => setShowWooCustomerDetails(false)}
              />
            )}
            {showWooCustomerDetails && (
              <WooCustomersPopup
                customer={wooCustomerDetails}
                onClose={() => setShowWooCustomerDetails(false)}
              />
            )}

          </div>
        </div>
      )}
      {nttCustomers && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >

          <div>
            <div className="flex justify-between items-center p-4 pl-6">
              <div>
                <h1 className="text-2xl font-bold">Customers (Local)</h1>
                <p>View recently created local customers.</p>
              </div>

              <div className="flex items-center space-x-4 m-2">
                <button className="add-new-token btn" onClick={createCustomerModalButton}>
                  <AddnewIcon className="mr-1" />
                  Create Customer
                </button>

                <button
                  className="cancel-button text-sm font-semibold  text-white py-2 px-4 rounded"
                  onClick={handleCancel}>
                  Cancel
                </button>
              </div>

            </div>
          </div>

          <div className="flex justify-between items-center p-4 pl-6">
            <div className="flex-grow overflow-auto bg-white rounded-lg shadow-2xl m-6">
              <table className="table-auto w-full">
                <thead className="bg-gray-100">
                  <tr className="text-left">
                    <th className="px-6 py-4">Customer Number</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Company</th>
                    <th className="px-6 py-4">Action</th>
                    <th className="px-6 py-4">Edit</th>
                    {localStorage.getItem('canDeleteCustomer') === "true" && (
                      <th className="px-6 py-4">Delete</th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td
                          className="px-6 py-4 text-[#4C7021] hover:underline cursor-pointer hover:text-[#4C7021]"
                          onClick={() => handleCustomerClick(item.customer.nonce)}
                        >
                          {item.customer.customer_number}
                        </td>
                        <td className="px-6 py-4">{`${item.customer.first_name} ${item.customer.last_name}`}</td>
                        <td className="px-6 py-4">{item.customer.email}</td>
                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleOrderClick(item.customer.nonce)}
                            className="bg-yellowsea hover:bg-yellowsea font-semibold text-white py-2 px-4 rounded cursor-pointer"
                          >
                            Sale
                          </button>
                        </td>
                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleEditClick(item.customer.nonce)}
                            className="bg-[#4C7021] hover:bg-[#3a5317] text-white font-semibold py-2 px-4 rounded cursor-pointer"
                          >
                            Edit
                          </button>
                        </td>
                        {localStorage.getItem('canDeleteCustomer') === "true" && (
                          <td className="px-6 py-4">
                            <React.Fragment>
                              <Button
                                variant="outlined"
                                color="danger"
                                onClick={() => handleOpenModal(item.customer.nonce)}
                              >
                                Delete
                              </Button>
                              <Modal open={open} onClose={() => setOpen(false)}>
                                <ModalDialog variant="outlined" role="alertdialog">
                                  <DialogTitle>
                                    <span className="font-bold">Delete Customer</span>
                                  </DialogTitle>
                                  <Divider />
                                  <DialogContent>
                                    You are about to delete this customer, are you sure?
                                  </DialogContent>
                                  <DialogActions>
                                    <Button variant="solid" color="danger"
                                      onClick={() => deleteCustomer(currentNonce)}
                                    >
                                      Delete
                                    </Button>
                                    <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
                                      Cancel
                                    </Button>
                                  </DialogActions>
                                </ModalDialog>
                              </Modal>
                            </React.Fragment>

                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>


              {showEditPopup && selectedCustomerForEdit && (
                <EditCustomerPopup
                  customer={selectedCustomerForEdit}
                  onClose={() => {
                    setShowEditPopup(false);
                    setSelectedCustomerForEdit(null);
                  }}
                />
              )}

              {showPopup && (
                <DeletePopup onClose={handleClosePopup} onDelete={handleConfirmDelete} />
              )}

              {selectedCustomerDetails && (
                <CustomersPopup
                  customer={selectedCustomerDetails}
                  onClose={() => setSelectedCustomerDetails(null)}
                />
              )}

              {message && (
                <div className="flex flex-col items-center justify-center h-64">
                  <NoDataIcon />
                  <div className="text-lg font-semibold text-gray-600">{message}</div>
                </div>
              )}

              {isLoading && (
                <div className="flex flex-col items-center justify-center h-64">
                  <div>Loading...</div>
                </div>
              )}
            </div>
          </div>

        </motion.div>
      )}
      {createCustomerForm && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center w-full min-h-screen pt-10"
        >

          <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">
            <div>
              <div className="flex justify-between items-center py-6">
                <div>
                  <h1 className="token-title">Create a Customer</h1>
                </div>
                <div className="flex">


                  <LoadingButton
                    onClick={mailingAndServiceAddress}
                    className={`save-and-continue text-sm font-semibold mr-2 ${createCustomerLoading ? 'loading' : ''}`}
                    loading={createCustomerLoading}
                    loadingIndicator="Loading…"
                    variant="contained"
                    disabled={!allFieldsFilled()}
                    sx={{
                      backgroundColor: '#4C7021',
                      color: 'white',
                      fontWeight: 550,
                      '&:hover': {
                        backgroundColor: '#3c591a',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#ffc33e',
                      },
                      '&.loading': {
                        width: '100px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                      },
                    }}
                  >
                    <span>Save</span>
                  </LoadingButton>


                  <button
                    className="cancel-button text-sm font-semibold text-white py-2 px-4 rounded ml-2"
                    onClick={cancelCreateCustomerForm}
                  >
                    Cancel
                  </button>
                </div>

              </div>

              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label className="text-sm font-semibold">First Name</label>
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                  <label className="text-sm font-semibold">Last Name</label>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>
              </div>


              {/* Username and Password */}
              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label className="text-sm font-semibold">Company</label>
                  <input
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                </div>
              </div>


              <div className="flex justify-center mt-4 border-b border-gray-300">
                <button
                  className={`py-2 px-4 ${activeTab === "contact" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("contact")}
                >
                  Contact
                </button>
                <button
                  className={`py-2 px-4 ml-2 ${activeTab === "mailing" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("mailing")}
                >
                  Mailing Address
                </button>

                <button
                  className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("service-address")}
                >
                  Service Address
                </button>

              </div>

              <div className="mt-4">
                {activeTab === "contact" && (
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex-1 mr-2">
                        <label className="text-sm font-semibold">Phone Number:</label>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="---"
                          className="w-full input-style"
                          type="text"
                        />
                      </div>

                      <div className="flex-1 ml-2">
                        <label className="text-sm font-semibold">Email:</label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="---"
                          className="w-full input-style"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "mailing" && (
                  <div>
                    <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                    <input
                      value={mailing_address}
                      onChange={(e) => setMailing_address(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">Street 1:</label>
                          <input
                            value={mailing_street1}
                            onChange={(e) => setMailing_street1(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">Street 2:</label>
                          <input
                            value={mailing_street2}
                            onChange={(e) => setMailing_street2(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>


                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">City:</label>
                          <input
                            value={mailing_city}
                            onChange={(e) => setMailing_city(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">State:</label>

                          <select
                            value={mailing_state}
                            onChange={(e) => setMailing_state(e.target.value)}
                            className="mb-2 w-full input-style cursor-pointer">
                            <option>---</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <label className="text-sm font-semibold">Zip:</label>
                    <input
                      value={mailing_zip}
                      onChange={(e) => setMailing_zip(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />
                  </div>
                )}

                {activeTab === "service-address" && (
                  <div>
                    <label className="text-sm font-semibold">Service Address Nickname:</label>
                    <input
                      value={service_address}
                      onChange={(e) => setService_address(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">Street 1:</label>
                          <input
                            value={service_street1}
                            onChange={(e) => setService_street1(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">Street 2:</label>
                          <input
                            value={service_street2}
                            onChange={(e) => setService_street2(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>


                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">City:</label>
                          <input
                            value={service_city}
                            onChange={(e) => setService_city(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">State:</label>
                          <select
                            value={service_state}
                            onChange={(e) => setService_state(e.target.value)}
                            className="mb-2 w-full input-style cursor-pointer">
                            <option>---</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <label className="text-sm font-semibold">Zip:</label>
                    <input
                      value={service_zip}
                      onChange={(e) => setService_zip(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />
                  </div>
                )}



              </div>

            </div>
          </div>
        </motion.div>
      )}

    </motion.div>
  );



}
