import React from "react";

export function EProtectPayment() {
  return (
    <div>
      <iframe
        title="eProtect Payment"
        src="https://dev-api.ntt.tools/v1/eprotect/init?id=4afe11bb202a4ae9857da7f2bf5660e3&total_amount=1&order_id=54321"
        width="100%"
        height="600"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}
