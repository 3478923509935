import React, { createContext, useState, useContext } from 'react';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [orderClicked, setOrderClicked] = useState(false);
    const [customerNttNonce, setCustomerNttNonce] = useState(null);

    return (
        <NavigationContext.Provider value={{ orderClicked, setOrderClicked, customerNttNonce, setCustomerNttNonce }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => useContext(NavigationContext);
