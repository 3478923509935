import React from "react";
import { A } from "hookrouter";
import { motion } from "framer-motion";

export function NavItem({ children, selected, linkTo = "" }) {
  return (
    <motion.li
      animate
      className={`py-3 flex relative ${selected && "text-shadow-inset"}`}
    >

      <A
        href={linkTo}
        className="pl-12 text-base  flex items-center relative z-10"
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              fillColor: selected ? "black" : "black",
              strokeColor: selected ? "black" : "black",
            });
          }
          return child;
        })}
      </A>


    </motion.li>
  );
}
