
import React, { useState, useEffect } from "react";
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { TextField, Container, Typography, Box } from '@mui/material';

import { useNotifications } from './NotificationContext';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function AlertDialogModal() {


    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "shipping_zones";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }


        const url =
            protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);



    const [open, setOpen] = React.useState(false);

    const { showNotification } = useNotifications();

    const [formData, setFormData] = useState({
        line1: "",
        line2: "",
        line3: "",
        city: "",
        region: "",
        postalCode: "",
        country: "US"
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [error, setError] = useState(null);
    const handleSubmit = (e) => {
        e.preventDefault();
        const eid = localStorage.getItem("default");
        const payload = {
            ...formData,
            eid: eid,
            endpoint: "v1/tax/avalara/resolveaddress"
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };
        fetch('/admin/postData.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response) {
                    const { messages, validatedAddresses } = data.response;

                    if (messages && messages[0].severity === "Error") {
                        const Notificationpayload = {
                            response: false,
                            subject: "Address",
                            operation: "Finding",
                            context: "Address is incomplete or invalid",
                            action: "Details",
                            summary: messages[0].summary,
                            details: messages[0].details,
                        };
                        console.log(messages[0].summary)
                        showNotification(Notificationpayload);
                    } else {
                        const Notificationpayload = {
                            response: true,
                            subject: "Address",
                            operation: "Found",
                            context: "Address found",
                            action: "Details",

                            addressDetails: validatedAddresses[0]
                        };
                        showNotification(Notificationpayload);
                    }
                }
            })
            .catch(() => {
                const Notificationpayload = {
                    response: false,
                    subject: "Address",
                    operation: "Finding",
                    context: "Address is incomplete or invalid",
                    action: "Details",
                };
                showNotification(Notificationpayload);
            });

    };



    const handleCreateTransaction2 = () => {


        const payload = {

            eid: localStorage.getItem('default'),
            endpoint: "v1/tax/avalara/resolveaddress",


            line1: "5665 John F. Kennedy Blvd",
            line2: "",
            line3: "",
            city: "North Bergen",
            region: " NJ",
            postalCode: "07047",
            country: "US"
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleCreateTransaction = () => {


        const payload = {

            eid: localStorage.getItem('default'),
            endpoint: 'v1/tax/avalara/resolve/mailing/and/service/address',

            mailing_line1: "12p",
            mailing_line2: "",
            mailing_line3: "",
            mailing_city: "Clayton",
            mailing_region: "GA",
            mailing_postalCode: "30055",
            mailing_country: "US",

            service_line1: "5665 John F. Kennedy Blvd",
            service_line2: "",
            service_line3: "",
            service_city: "North Bergen",
            service_region: " NJ",
            service_postalCode: "07047",
            service_country: "US"

        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };



    return (
        <div>
            <React.Fragment>
                <Button
                    variant="outlined"
                    color="danger"
                    onClick={() => setOpen(true)}
                >
                    Delete
                </Button>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog variant="outlined" role="alertdialog">
                        <DialogTitle>
                            <WarningRoundedIcon />
                            Confirmation
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            Are you sure you want to discard all of y
                        </DialogContent>
                        <DialogActions>
                            <Button variant="solid" color="danger" onClick={() => setOpen(false)}>
                                Discard notes
                            </Button>
                            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </ModalDialog>
                </Modal>
            </React.Fragment>

            <Container maxWidth="sm">
                <Typography variant="h4" component="h1" gutterBottom>
                    Address Found
                    <Tooltip title="Avalaras Endpoint Found this address, it might not match the current address sent in the payload" arrow placement="right" sx={{ p: 1 }}>
                        <IconButton size="small">
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Line 1"
                        name="line1"
                        value={formData.line1}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Line 2"
                        name="line2"
                        value={formData.line2}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Line 3"
                        name="line3"
                        value={formData.line3}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="City"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Region"
                        name="region"
                        value={formData.region}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Postal Code"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Container>

            <select
                className="w-full input-style"
                disabled
            >

            </select>


            <button onClick={handleCreateTransaction}>Create Transaction</button>

            <button onClick={handleCreateTransaction2}>Create Transaction 2</button>



        </div>
    );
}
