import React, { useState, useEffect, useContext } from 'react';
import { motion } from "framer-motion";
import { AddnewIcon, DeleteIcon } from '../assets/icons/hiking';
import { useNotifications } from './NotificationContext';

import LoadingButton from '@mui/lab/LoadingButton';

import { SearchContext } from './searchContext';

export default function Users() {

    const [isFormVisible, setIsFormVisible] = useState(true);
    const { showNotification } = useNotifications();
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [data, setData] = useState([]);

    const { searchTerm } = useContext(SearchContext);

    const [isLoading, setIsLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(0);

    useEffect(() => {

        const type = "login";
        const eid = localStorage.getItem('default');
        const nonce = localStorage.getItem('nonce');

        function protocolAndPath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        setIsLoading(true);
        setData([]);
        const url = protocolAndPath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&search=' + searchTerm + '&nonce=' + nonce;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data.data.response);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [triggerFetch, searchTerm]);





    const [isLimitExceeded, setIsLimitExceeded] = useState(false);

    const handleChange = (event) => {
        const value = event.target.value;
        if (selectedOptions.length >= 5) {
            setIsLimitExceeded(true);
        } else {
            if (value !== 'Select' && !selectedOptions.includes(value)) {
                setSelectedOptions([...selectedOptions, value]);
                setIsLimitExceeded(false);
            }
        }
    };


    const removeOption = (optionToRemove) => {
        setSelectedOptions(selectedOptions.filter(option => option !== optionToRemove));
        if (isLimitExceeded) setIsLimitExceeded(false);
    };




    // Create user  
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [api, setApi] = useState('');
    const [accessid, setAccessID] = useState('');


    const [responseAddUser, setResponseAddUser] = useState('');
    const [isSuccess, setIsSuccess] = useState(true);

    const [createUserLoading, setCreateUserLoading] = useState(false);

    function CreateUser() {
        const channels = Array(5).fill("Select");
        const eid = localStorage.getItem('default');

        selectedOptions.forEach((option, index) => {
            if (index < 5) { // only  5 channels
                channels[index] = option;
            }
        });

        const payload = {
            username,
            password,
            api,
            accessid,
            channel_1: "Root",
            channel_2: channels[1],
            channel_3: channels[2],
            channel_4: channels[3],
            channel_5: channels[4],

            endpoint: "v1/login/add",
            eid: eid,
        };
        setCreateUserLoading(true);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.response && data.response.nonce) {
                    const Notificationpayload = {
                        response: true,
                        subject: "User",
                        operation: "Created",
                        context: "",
                    };
                    setUsername('');
                    setPassword('');
                    setApi('');
                    setAccessID('');

                    showNotification(Notificationpayload);
                    setIsFormVisible(true);
                    setTriggerFetch(prev => prev + 1);
                } else if (data.response && data.response.nonce === "") {
                    const Notificationpayload = {
                        response: true,
                        subject: "User",
                        operation: "Created",
                        context: "",
                    };

                    setUsername('');
                    setPassword('');
                    setApi('');
                    setAccessID('');

                    setTriggerFetch(prev => prev + 1);
                    setIsFormVisible(true);
                    showNotification(Notificationpayload);
                } else if (data.response && data.response === "NO CHANNELS SELECTED") {
                    const Notificationpayload = {
                        response: false,
                        subject: "User",
                        operation: "Creating",
                        context: "NO CHANNELS SELECTED",
                    };
                    showNotification(Notificationpayload);
                }
                else {
                    const Notificationpayload = {
                        response: false,
                        subject: "User",
                        operation: "Creating",
                        context: "",
                    };
                    showNotification(Notificationpayload);
                }
            })
            .catch(() => {
                const Notificationpayload = {
                    response: false,
                    subject: "User",
                    operation: "Creating",
                    context: "",
                };
                showNotification(Notificationpayload);
            })
            .finally(() => {
                setCreateUserLoading(false);
            });
    }


    const allFieldsFilled = () => {
        return (
            username &&
            password &&
            api
            // accessid
        );
    };

    const cancelCreateUser = () => {
        setIsFormVisible(true);

        setUsername('');
        setPassword('');
        setApi('');
        setAccessID('');
    }


    // channels
    const getChannelsString = (channels) => {
        let channelsString = [];
        Object.values(channels).forEach(channel => {
            const channelName = Object.values(channel)[0];
            if (channelName[0] !== "Select") {
                channelsString.push(channelName[0]);
            }
        });
        return channelsString.join(' -- ');
    };

    // user (view details)


    const handleUserClick = (userNonce) => {
        fetchUserDetails(userNonce);
    };

    const [selectedUserDetails, setSelectedUserDetails] = useState(null);

    const fetchUserDetails = (userNonce) => {
        const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';

        const eid = localStorage.getItem('default');
        const type = 'login'
        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&id=' + encodeURIComponent(userNonce);

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data:', data);
                if (data.success && data.data.response) {
                    setSelectedUserDetails(data.data.response[0].login);
                }

            })
            .catch(error => console.error('Error:', error));
    };

    function UserDetailsPopup({ user, onClose }) {
        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
                    <h2 className="text-lg font-semibold mb-4">View Details:  {user.username}</h2>
                    {user && (
                        <>
                            <p><span>Access ID: </span><span>{user.accessid}</span></p>
                            <p><span>Created Date: </span><span>{user.created_date}</span></p>
                            <p><span>Updated Date: </span><span>{user.updated_date}</span></p>
                            <p><span>Suspended Date: </span><span>{user.suspended_date}</span></p>
                        </>
                    )}
                    <div className="flex justify-end mt-6">
                        <button className="py-2 px-4 text-white rounded bg-gray-500 hover:bg-gray-700" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        );
    }

    // Edit user
    const handleEditClick = (userNonce) => {
        fetchUserEdit(userNonce);
    };
    const [nonce, setNonce] = useState('');
    const [selectedEditPopup, setEditPopup] = useState(null);
    const fetchUserEdit = (userNonce) => {
        const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';

        const eid = localStorage.getItem('default');
        const type = 'login'
        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&id=' + encodeURIComponent(userNonce);

        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log('Data:', data);
                if (data.success && data.data.response) {
                    setEditPopup(data.data.response[0]);
                    setNonce(userNonce);
                }
            })
            .catch(error => console.error('Error:', error));
    };

    function EditPopup({ user, onClose }) {

        const [username, setUsername] = useState(user.login.username);
        const [password, setPassword] = useState(user.login.password);
        const [api, setApi] = useState(user.login.api);
        const [accessid, setAccessid] = useState(user.login.accessid);

        const [channels, setChannels] = useState(user.channel_enum);


        const handleChange = (e) => {
            const { name, value } = e.target;

            switch (name) {
                case 'username':
                    setUsername(value);
                    break;
                case 'password':
                    setPassword(value);
                    break;
                case 'api':
                    setApi(value);
                    break;
                case 'accessid':
                    setAccessid(value);
                    break;
                default:
                    setSelectedChannels(prev => ({
                        ...prev,
                        [name]: value,
                    }));
                    break;
            }
        };


        const [selectedChannels, setSelectedChannels] = useState({
            channel_1: user.channel_enum[0]?.channel_1?.[0] || '',
            channel_2: user.channel_enum[1]?.channel_2?.[0] || '',
            channel_3: user.channel_enum[2]?.channel_3?.[0] || '',
            channel_4: user.channel_enum[3]?.channel_4?.[0] || '',
            channel_5: user.channel_enum[4]?.channel_5?.[0] || '',
        });



        const eid = localStorage.getItem('default');

        const handleUpdate = () => {
            const payload = {
                username: username,
                password: password,
                api: api,
                accessid: accessid,
                nonce: nonce,
                ...selectedChannels,

                endpoint: 'v1/login/update',
                eid: eid
            };

            fetch('/admin/postData.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success && data.response === "OK") {
                        const Notificationpayload = {
                            response: true,
                            subject: "User",
                            operation: "Updated",
                            context: "",
                        };
                        showNotification(Notificationpayload);
                        setTriggerFetch(prev => prev + 1);
                        onClose();
                    } else if (data.success && data.response === "NO CHANNELS SELECTED") {
                        const Notificationpayload = {
                            response: false,
                            subject: "User",
                            operation: "Updating",
                            context: "NO CHANNELS SELECTED",
                        };
                        showNotification(Notificationpayload);
                        onClose();
                    } else {
                        const Notificationpayload = {
                            response: false,
                            subject: "User",
                            operation: "Updating",
                            context: "",
                        };
                        setuserChangeNonce(null);
                        showNotification(Notificationpayload);
                        onClose();
                    }
                })
                .catch(() => {
                    const Notificationpayload = {
                        response: false,
                        subject: "Error",
                        operation: "Updating",
                        context: "",
                    };
                    setuserChangeNonce(null);
                    showNotification(Notificationpayload);
                    onClose();
                });
        };

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-start pt-10 z-50">
                <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto">
                    <h1 className="text-lg font-semibold mb-4">Edit User</h1>

                    <label className="text-sm font-semibold">Username</label>
                    <input type="text"
                        name="username"
                        className="w-full input-style"
                        value={username}
                        onChange={handleChange} />

                    <label className="text-sm font-semibold">Password</label>
                    <input
                        type="text"
                        name="password"
                        className="w-full input-style"
                        value={password}
                        onChange={handleChange} />

                    <label className="text-sm font-semibold">API</label>
                    <input
                        type="text"
                        name="api"
                        className="w-full input-style"
                        value={api}
                        onChange={handleChange} />

                    <label className="text-sm font-semibold">Access ID</label>
                    <input
                        type="text"
                        name="accessid"
                        className="w-full input-style"
                        value={accessid}
                        onChange={handleChange} />

                    {channels.map((channel, index) => {
                        const channelKey = Object.keys(channel)[0];
                        const channelValues = channel[channelKey];

                        return (
                            <div key={index}>
                                <label className="text-sm font-semibold">{`Channel ${index + 1}`}</label>
                                <select
                                    name={channelKey}
                                    className="w-full input-style"
                                    value={selectedChannels[channelKey] || ''}
                                    onChange={handleChange}
                                >
                                    {channelValues.map((value, optionIndex) => (
                                        <option key={optionIndex} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        );
                    })}

                    <div className="flex justify-between items-center mt-6">
                        <div>
                            <button className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={onClose}>Close</button>
                            <button className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={handleUpdate}>Update</button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }


    //Delete user
    const [channelNonce, setChannelNonce] = useState(null);

    const handleDeleteClick = (nonce) => {
        setChannelNonce(nonce);
    };

    const handleConfirmDelete = () => {
        const eid = localStorage.getItem('default');
        const payload = {
            eid,
            nonce: channelNonce,
            endpoint: "v1/login/delete"
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    const Notificationpayload = {
                        response: true,
                        subject: "User",
                        operation: "Deleted",
                        context: "",
                    };
                    showNotification(Notificationpayload);

                    setChannelNonce(null);
                    setTriggerFetch(prev => prev + 1);
                } else {
                    const Notificationpayload = {
                        response: false,
                        subject: "User",
                        operation: "Deleting",
                        context: "",
                    };
                    setChannelNonce(null);
                    showNotification(Notificationpayload);
                }
            })
            .catch(() => {
                const Notificationpayload = {
                    response: false,
                    subject: "User",
                    operation: "Deleting",
                    context: "",
                };
                showNotification(Notificationpayload);
                setChannelNonce(null);
            });
    };

    function DeleteConfirmationPopup({ onClose, onConfirm }) {
        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
                <div className="bg-white p-5 rounded-lg shadow-lg text-center">
                    <h2 className="text-xl font-bold mb-4">Delete User</h2>
                    <p className="flex justify-center gap-4 mt-4">
                        You are about to delete this user. Are you sure?
                    </p>
                    <div className="flex justify-center gap-4 mt-4">
                        <button
                            className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={onConfirm}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    //Suspend user
    const [userChangeNonce, setuserChangeNonce] = useState(null);

    const handleSuspendClick = (nonce) => {
        setuserChangeNonce(nonce)
    };

    const handleConfirmChange = () => {
        const eid = localStorage.getItem('default');
        const payload = {
            eid,
            nonce: userChangeNonce,
            endpoint: "v1/login/change"
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {

                    const Notificationpayload = {
                        response: true,
                        subject: "Status",
                        operation: "Updated",
                        context: "",
                    };
                    // Status Changed Successfully (notification)
                    showNotification(Notificationpayload);

                    setTriggerFetch(prev => prev + 1);
                    setuserChangeNonce(null);
                } else {
                    const Notificationpayload = {
                        response: false,
                        subject: "Status",
                        operation: "Updating",
                        context: "",
                    };
                    setuserChangeNonce(null);
                    showNotification(Notificationpayload);
                }
            })
            .catch(() => {
                const Notificationpayload = {
                    response: false,
                    subject: "Status",
                    operation: "Updating",
                    context: "",
                };
                setuserChangeNonce(null);
                showNotification(Notificationpayload);
            });
    };

    function ChangeConfirmationPopup({ onClose, onConfirm }) {
        return (

            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
                <div className="bg-white p-5 rounded-lg shadow-lg text-center">
                    <h2 className="text-xl font-bold mb-4">Change Status</h2>
                    <p className="flex justify-center gap-4 mt-4">
                        You are about to Change the status of this user. Are you sure?
                    </p>
                    <div className="flex justify-center gap-4 mt-4">
                        <button
                            className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={onConfirm}
                        >
                            Change
                        </button>
                    </div>
                </div>
            </div>
        );
    }



    const [timeZoneOffset, setTimeZoneOffset] = useState(() => {
        const savedOffset = localStorage.getItem('time_offset');
        return savedOffset || '-5'; // if there is nothing in localStorage, use -5 as default
    });


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container-tokens"
        >
            {isFormVisible ? (
                <div>

                    <div className="flex justify-between items-center p-4 pl-6">
                        <div>
                            <h1 className="text-2xl font-bold">Users List</h1>
                            <p>View Users currently found in NTT.</p>
                        </div>

                        <div className="flex items-center space-x-4 m-2 " onClick={() => setIsFormVisible(false)}>
                            <button className="add-new-token btn">
                                Create a User
                            </button>
                        </div>
                    </div>

                    <div className="flex-grow overflow-auto bg-white rounded-lg shadow-2xl m-6">
                        <table className="table-auto w-full">
                            <thead className="bg-gray-100">
                                <tr className="text-left">
                                    <th className="px-6 py-4">#</th>
                                    <th className="px-6 py-4">User</th>
                                    <th className="px-6 py-4">Timezone</th>
                                    <th className="px-6 py-4">Delete</th>
                                    <th className="px-6 py-4">Suspend</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className="border-b border-gray-200">
                                        <td className="px-6 py-4 break-words">{item.id}</td>
                                        <td className="text-blue-500 hover:underline cursor-pointer hover:text-blue-700"
                                            onClick={() => handleUserClick(item.login.nonce)}>{item.login.username}
                                        </td>
                                        <td>(UTC-5:00)</td>
                                        <td>
                                            <button
                                                onClick={() => handleDeleteClick(item.login.nonce)}
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded cursor-pointer">
                                                Delete
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => handleSuspendClick(item.login.nonce)}
                                                className={`bg-${!item.login.suspended_date ? 'yellow' : 'blue'}-500 hover:bg-${!item.login.suspended_date ? 'yellow' : 'blue'}-700 text-white font-bold py-2 px-4 rounded cursor-pointer`}>
                                                {!item.login.suspended_date ? 'Suspend' : 'Activate'}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>


                            {/* view details of the user popup */}
                            {selectedUserDetails && <UserDetailsPopup user={selectedUserDetails} onClose={() => setSelectedUserDetails(null)} />}
                            {/* Edit user popup */}
                            {selectedEditPopup && <EditPopup user={selectedEditPopup} onClose={() => setEditPopup(null)} />}

                            {channelNonce && (
                                <DeleteConfirmationPopup
                                    onClose={() => setChannelNonce(null)}
                                    onConfirm={handleConfirmDelete}
                                />
                            )}

                            {userChangeNonce && (
                                <ChangeConfirmationPopup
                                    onClose={() => setuserChangeNonce(null)}
                                    onConfirm={handleConfirmChange}
                                />
                            )}

                        </table>

                        {isLoading && (
                            <div className="flex flex-col items-center justify-center h-64">
                                <div>Loading...</div>
                            </div>
                        )}

                    </div>

                </div>

            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="pt-5"
                >
                    <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">
                        <div>
                            <div className="flex justify-between items-center py-6">
                                <div>
                                    <h1 className="token-title">Create a User</h1>
                                </div>
                                <div className="flex">


                                    <LoadingButton
                                        onClick={CreateUser}
                                        className={`save-and-continue text-sm font-semibold mr-2 ${createUserLoading ? 'loading' : ''}`}
                                        loading={createUserLoading}
                                        loadingIndicator="Loading…"
                                        variant="contained"
                                        disabled={!allFieldsFilled()}
                                        sx={{
                                            backgroundColor: '#4C7021',
                                            color: 'white',
                                            fontWeight: 550,
                                            '&:hover': {
                                                backgroundColor: '#3c591a',
                                            },
                                            '&.Mui-disabled': {
                                                backgroundColor: '#ffc33e',
                                            },
                                            '&.loading': {
                                                width: '100px',
                                                paddingLeft: '16px',
                                                paddingRight: '16px',
                                            },
                                        }}
                                    >
                                        <span>Save</span>
                                    </LoadingButton>


                                    <button
                                        className="cancel-button text-sm font-semibold text-white py-2 px-4 rounded ml-2"
                                        onClick={cancelCreateUser}
                                    >
                                        Cancel
                                    </button>
                                </div>

                            </div>

                            <label className="text-sm font-semibold">
                                Username:
                            </label>
                            <input
                                placeholder="---"
                                className="mb-2 w-full input-style"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />


                            <label className="text-sm font-semibold">
                                Password:
                            </label>
                            <input
                                placeholder="---"
                                className="mb-2 w-full input-style"
                                type="text"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <label className="text-sm font-semibold">
                                API:
                            </label>
                            <input
                                placeholder="---"
                                className="mb-2 w-full input-style"
                                type="text"
                                value={api}
                                onChange={(e) => setApi(e.target.value)}
                            />

                            <label className="text-sm font-semibold">AccessID</label>
                            <input
                                placeholder="---"
                                className="mb-2 w-full input-style"
                                type="text"
                                value={accessid}
                                onChange={(e) => setAccessID(e.target.value)}
                            />

                            <label htmlFor="timeZoneOffset" className="text-sm font-semibold">Timezone Offset</label>
                            <select
                                id="timeZoneOffset"
                                className="mb-2 w-full input-style"
                                value={timeZoneOffset}
                                onChange={(e) => setTimeZoneOffset(e.target.value)}
                            >
                                <option value="-5">UTC-5 (EST)</option>
                                <option value="-6">UTC-6 (CST)</option>
                                <option value="-7">UTC-7 (MST)</option>
                                <option value="-8">UTC-8 (PST)</option>
                            </select>

                        </div>
                    </div>

                </motion.div >
            )
            }
        </motion.div >
    );


}

