import React, { useEffect } from "react";
import "./assets/main.css";
import { useRoutes } from "hookrouter";
import AdminRouter from "./admin";
import Login from "./login";

import { ResponseProvider } from "./admin/ResponseContext";
import { NotificationProvider } from './admin/NotificationContext';
import { NavigationProvider } from './admin/NavigationContext';

import { SearchProvider } from './admin/searchContext';

export function Input({ passedRef, hasError, className, ...rest }) {
  return (
    <input
      className={`rounded-lg h-12 p-2 px-3 w-full placeholder-gray-400 ${className} ${hasError ? "" : "mb-4"
        }`}
      ref={passedRef}
      {...rest}
    />
  );
}

let orderId;

function setOrderId(ordId) {
  orderId = ordId;
}

function Success() {
  return (
    <div className="flex items-center justify-center w-full h-screen text-5xl"></div>
  );
}

let routes = {
  "/": () => () => <Login />,
  "/login": () => () => <Login />,
  "/app*": () => () => <AdminRouter />,
  "/success": () => ({ orderId }) => <Success orderId={orderId} />,
};

function App() {
  const routeResult = useRoutes(routes);

  return (
    <NavigationProvider>
      <NotificationProvider>
        <ResponseProvider>
          <SearchProvider>
            <div className="h-full">
              <div className="flex justify-center w-full h-full mx-auto">
                {routeResult({ orderId, setOrderId }) || <div>Nothing Found</div>}
              </div>
            </div>
          </SearchProvider>
        </ResponseProvider>
      </NotificationProvider>
    </NavigationProvider>
  );
}

export default App;
