import React, { useContext, useEffect, useRef, useState } from "react";
import { NavItem } from "./components";
import { useRoutes, navigate } from "hookrouter";
import { Transactions } from "./customers";
import { Payers } from "./orders";
import AlertDialogModal from "./testing";
import { Input } from "../App";
import { AnimatePresence, motion, } from "framer-motion";
import { Logout } from "heroicons-react";
import { logout } from "../utils/fetch";
import Plans from "./home";
import Users from "./users";
import { IconOverview, IconOrders, IconHome, IconArrow, IconEnroll, CalendarIcon, UserIcon, PayIcon, DeleteIcon } from "../assets/icons/hiking";
import "react-toastify/dist/ReactToastify.css";

import { SearchContext } from './searchContext';
import { EProtectPayment } from "../assets/forms/eProtectPayment";


import { ResponseContext } from './ResponseContext';

const routes = {
  "/": () => <Logout />,
  "/home": () => <Plans />,
  "/customers": () => <Transactions />,
  "/orders": () => <Payers />,
  "/user-management": () => <Users />,

  "/testing": () => <AlertDialogModal />,
  "/iframe": () => <EProtectPayment />,
};


export default function Main() {

  const { setSearchTerm } = useContext(SearchContext);
  const routeResult = useRoutes(routes);

  const { responseMessage, clearResponseMessage, errorMessage, clearErrorMessage } = useContext(ResponseContext);

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        clearResponseMessage();
      }, 40000);

      return () => clearTimeout(timer);
    }
  }, [responseMessage, clearResponseMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        clearErrorMessage();
      }, 40000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage, clearErrorMessage]);

  // header

  const shouldHideSearch = ['/app/home', '/app/orders', '/app/user-management'].includes(window.location.pathname);


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  // show the date
  const today = new Date();
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };



  return (
    <div className="flex w-full h-screen mx-auto">
      <div className="fixed h-full pr-10 pb-6  body-container">
        <div className="flex flex-col justify-between w-72 h-full rounded-lg bg-firefly sidebar">
          <div className="flex flex-col items-center p-2 pt-6 pb-6">
            <img
              className="mm-logo cursor-pointer"
              src={`https://5ca93112550f8dcf11d2-5a9e76d0467760b267bf96203a242b5e.ssl.cf1.rackcdn.com/MM_Logo.png`}
              alt="Moultrie Mobile Logo"
              onClick={() => navigate('home')}
            />
          </div>
          <div className="flex-grow flex flex-col justify-center mb-60 mt-20">
            <motion.ul className="flex flex-col">
              <NavItem
                linkTo="/app/home"
                selected={routeResult.type === Plans}
              >
                <IconHome className="-ml-3 mr-2 nav-icon" />
                Home
              </NavItem>

              <NavItem
                linkTo="/app/customers"
                selected={routeResult.type === Transactions}
              >
                <IconOverview className="-ml-3 mr-2 nav-icon" />
                Customers
              </NavItem>

              <NavItem
                linkTo="/app/orders"
                selected={routeResult.type === Payers}
              >
                <IconOrders className="-ml-3 mr-2 nav-icon" />
                Orders
              </NavItem>

              <NavItem
                linkTo="/app/user-management"
                selected={routeResult.type === Users}
              >
                <IconEnroll className="-ml-3 mr-2 nav-icon" />
                User Management
              </NavItem>


            </motion.ul>
          </div>

          <div className="p-6 text-center">
            <div
              onClick={logout}
              className="flex justify-center cursor-pointer"
            >
              <Logout className="pr-2" />
              Logout
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full pt-4 text-gray-800"
        style={{ marginLeft: "290px", paddingTop: "0" }}
      >

        {/* header */}
        <div className="header-tokens flex justify-between">

          <div className="flex items-center">
            {!shouldHideSearch && (
              <input
                type="text"
                placeholder="Customer Search"
                className="input-tokens"
                onChange={handleChange}
              />
            )}
          </div>


          {localStorage.getItem("username") ? (

            <div className="flex items-center space-x-10 px-3">

              <div className="flex items-center gap-2">
                <CalendarIcon />
                <span>{formattedDate}</span>
              </div>

              <div className="relative">
                <div onClick={toggleMenu} className="cursor-pointer flex items-center">
                  <div className="bg-gray-400 h-10 w-10 rounded-full mr-2"></div>
                  <div>
                    <div className="font-semibold">{localStorage.getItem("username")}</div>
                    <div className="text-gray-600 text-sm">Customer Support</div>
                  </div>
                </div>

                {isMenuOpen && (
                  <div ref={menuRef} className="absolute z-50 mt-2 bg-white shadow-md border border-gray-200 rounded-md right-0 transform translate-x-full" style={{ width: '264px', transform: 'translateX(10px)' }}>
                    <ul>
                      <li className="px-4 py-2 border-b border-gray-200" style={{ fontWeight: '500' }}>
                        <div>{localStorage.getItem("username")}</div>
                        <div style={{ fontSize: 'small', fontWeight: '500' }}>email@example.com</div>
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200" style={{ fontWeight: '500' }}>My account</li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200" style={{ fontWeight: '500' }}>Contact Us</li>
                      <li className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200" onClick={logout} style={{ fontWeight: '500' }}>
                        <span>Log out</span>
                        <Logout className="text-xl" />
                      </li>
                      <li onClick={() => navigate('/app/testing')} className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200" style={{ fontWeight: '500' }}>Testing Tab</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div onClick={() => navigate('/login')} className="flex cursor-pointer items-center">
              <span
                className=" py-2 px-4 rounded"
              >
                Sign In
              </span>
              <UserIcon />
            </div>
          )}

        </div>

        <AnimatePresence exitBeforeEnter>
          {routeResult || <div>Nothing Found</div>}
        </AnimatePresence>
      </div>
    </div>
  );

}




