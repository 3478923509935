import React, { useState, useRef, useEffect } from "react";
import { useNotifications } from './NotificationContext';
import { useRoutes, navigate } from "hookrouter";
import "react-quill/dist/quill.snow.css";
import { AddnewIcon, NoDataIcon, DeleteIconx } from "../assets/icons/hiking";
import { motion } from "framer-motion";
import { useNavigation } from './NavigationContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { max } from 'moment';
import { TextField, Container, Box, Button, Grid, } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';


import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import CircularProgress from '@mui/material/CircularProgress';


import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from "@mui/material";




export function Payers() {

  const [orders, setOrders] = useState([]);
  const { showNotification } = useNotifications();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [message, setMessage] = useState('');


  const [customers, setCustomers] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(0);

  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [names, setNames] = useState([]);

  const [searchValue, setSearchValue] = useState('');


  function protocolpluspath() {
    return window.location.protocol + "//" + window.location.host + "/";
  }
  const [defaultCustomer, setDefaultCustomer] = useState(null);


  const fetchData = async (eid, type, nonce) => {
    const url = `${protocolpluspath()}admin/getData.php?eid=${eid}&type=${type}&search=&nonce=${nonce}`;
    try {
      setLoadingCustomers(true);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    } finally {
      setLoadingCustomers(false);
    }
  };
  const [defaultCustomerNonce, setDefaultCustomerNonce] = useState("");
  useEffect(() => {
    const combineData = async () => {
      const eid = localStorage.getItem("default");
      const nonce = localStorage.getItem("nonce");

      const customerData = await fetchData(eid, "customer", nonce);
      const userData = await fetchData(eid, "user", nonce);

      const combinedNames = [];

      if (customerData?.success && customerData.data?.success && customerData.data.count > 0 && customerData.data.response !== "NO") {
        combinedNames.push(...customerData.data.response.map(item => ({
          label: `${item.customer.first_name} ${item.customer.last_name} ${item.customer.customer_number}`,
          nonce: item.customer.nonce,
          type: 'customer'
        })));
      }

      if (userData && userData.success && userData.data.success && userData.data.count > 0 && userData.data.response !== "NO") {
        combinedNames.push(...userData.data.response.map(item => ({
          label: `${item.user.first_name} ${item.user.last_name}, ${item.user.email}  `,
          nonce: item.user.nonce,
          type: 'user'
        })));
      }

      setNames(combinedNames.reverse());

      // Logic conbined for defaultCustomerNonce y customerNttNonce
      const effectiveNonce = defaultCustomerNonce || customerNttNonce;
      if (effectiveNonce) {
        const defaultCustomer = combinedNames.find(item => item.nonce === effectiveNonce);
        setDefaultCustomer(defaultCustomer);
        setIsDefaultSet(true);
      }
    };

    combineData();
  }, [defaultCustomerNonce]);

  useEffect(() => {
    if (defaultCustomer && !isDefaultSet) {
      if (defaultCustomer.type === 'customer') {
        fetchCustomerDetails(defaultCustomer.nonce);
      } else if (defaultCustomer.type === 'user') {
        fetchUserDetails(defaultCustomer.nonce);
      }
    }
  }, [defaultCustomer]);

  useEffect(() => {
    if (defaultCustomerNonce) {
      fetchCustomerDetails(defaultCustomerNonce);
    }
  }, [defaultCustomerNonce]);



  const [searchOrder, setSearchOrder] = useState('');

  useEffect(() => {
    const eid = localStorage.getItem("default");
    const type = "woo_orders";

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }

    setIsLoadingOrders(true);
    setMessage('');

    const url =
      protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + '&search=' + searchOrder;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (data.data.success === 1 && data.data.count === 0 && data.data.response === "NO") {
            setMessage('No Data');
          } else {
            setOrders(data.data.response);
          }
        } else {
          setMessage('No Data');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage('No Data');
      })
      .finally(() => {
        setIsLoadingOrders(false);
      });
  }, [searchOrder]);


  const handleInputChange = (event) => {
    setSearchOrder(event.target.value);
  };

  // color of satus change based on status of the order
  const statusColors = {
    processing: "text-yellow-500",
    pending: "text-yellow-500",
    cancelled: "text-red-500",
    completed: "text-green-500"
  };

  ///popup
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  const openModal = (order) => {
    setCurrentOrder(order);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function OrderModal({ order, isOpen, onRequestClose }) {
    if (!order) return null;

    return (
      <div className={`fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full flex items-center justify-center ${!isOpen ? 'hidden' : ''}`}>
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Order Details</h2>
            <button onClick={onRequestClose} className="text-lg font-semibold leading-none text-gray-600 hover:text-gray-900">
              &times;
            </button>
          </div>
          <div className="space-y-2">
            <p><strong>Order ID:</strong> {order.order_id}</p>
            <p><strong>Amount:</strong> {order.order_amount}</p>
            <p><strong>Order Date:</strong> {order.order_date}</p>
            <p className="cursor-pointer">
              <strong
              >Name:
              </strong>
              {order.order_name}
            </p>
            <p><strong>Email:</strong> {order.order_email}</p>
            <p><strong>Status:</strong> {order.order_status}</p>
            <p><strong>Created Date:</strong> {order.created_date}</p>

            <hr className="border-t border-gray-300 my-4" />

            <p><strong>Code:</strong> {order.custom_1}</p>
            <p><strong>Status:</strong> {order.custom_4}</p>



          </div>
        </div>
      </div>
    );
  }


  // one form (orders, create customer, create order) at a time
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isNewOrderFormVisible, setIsNewOrderFormVisible] = useState(false);

  const handleNewOrderClick = () => {
    setIsNewOrderFormVisible(true);
    setIsFormVisible(false);
  };

  const handleNewCustomerClick = () => {
    setIsFormVisible(false);
    setIsNewOrderFormVisible(false);
  };


  const handleCloseNewOrderForm = () => {
    setIsNewOrderFormVisible(false);
    setIsFormVisible(true);
  };

  const handleCancel = () => {
    setProducts([]);
    setCustomerNttNonce(null);
    setDefaultCustomer(null)
    setIsFormVisible(true);
    setIsNewOrderFormVisible(false);
  };



  //products DEMO
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ sku: "", qty: "" });
  const [loadingProductButton, setLoadingProductButton] = useState(false);

  const fetchProduct = (sku) => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "product";
    const search = sku;

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }
    const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&nonce=" + nonce + "&search=" + search;

    setLoadingProductButton(true);

    fetch(url)
      .then(response => response.json())
      .then(details => {
        // adds the product to the table.
        if (details.success && details.data.response.length > 0) {
          const productDetails = details.data.response[0];
          handleAddProduct(
            productDetails.product.title,
            productDetails.properties.use_price,
            newProduct.qty,
            productDetails.product.nonce,
            search
          );

          const Notificationpayload = {
            response: true,
            subject: "Product",
            operation: "Added",
            context: ` "${productDetails.product.title}" has been added`,
          };
          showNotification(Notificationpayload);

          setBillingAddress('Select');
          setShippingAddress('Select');

          setTotalTax(0);
        } else {
          const Notificationpayload = {
            response: false,
            subject: "Product",
            operation: "Adding",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(error => {
        const Notificationpayload = {
          response: false,
          subject: "Product",
          operation: "Adding",
        };
        showNotification(Notificationpayload);
      })
      .finally(() => {
        setLoadingProductButton(false);
      });
  }



  const handleAddProduct = (title, use_price, qty, nonce, sku) => {
    const existingProductIndex = products.findIndex(product => product.sku === sku);

    if (existingProductIndex !== -1) {
      const updatedProducts = products.map((product, index) => {
        if (index === existingProductIndex) {
          return {
            ...product,
            qty: parseInt(product.qty) + parseInt(qty),
          };
        }
        return product;
      });
      setProducts(updatedProducts);
    } else {
      setProducts([
        ...products,
        {
          id: products.length + 1,
          title: title,
          use_price: use_price,
          qty: qty,
          nonce: nonce,
          sku: sku,
        },
      ]);
    }

    setNewProduct({ sku: "", qty: "" });
  };


  const handleSKUChange = (e) => {
    setNewProduct({ ...newProduct, sku: e.target.value });
  };
  const handleQuantityChange = (e) => {
    setNewProduct({ ...newProduct, qty: e.target.value });
  };

  const handleAddButtonClick = () => {
    if (newProduct.sku) {
      fetchProduct(newProduct.sku);
    }
  };

  const handleDeleteProduct = (id) => {
    const updatedProducts = products.filter(product => product.id !== id);
    setProducts(updatedProducts);

    setBillingAddress('Select');
    setShippingAddress('Select');

    setTotalTax(0);
  };




  // CREATE A CUSTOMER



  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [mailing_address, setMailing_address] = useState('');
  const [mailing_street1, setMailing_street1] = useState('');
  const [mailing_street2, setMailing_street2] = useState('');
  const [mailing_city, setMailing_city] = useState('');
  const [mailing_state, setMailing_state] = useState('');
  const [mailing_zip, setMailing_zip] = useState('');

  const [service_address, setService_address] = useState('');
  const [service_street1, setService_street1] = useState('');
  const [service_street2, setService_street2] = useState('');
  const [service_city, setService_city] = useState('');
  const [service_state, setService_state] = useState('');
  const [service_zip, setService_zip] = useState('');


  const handleCreateCustomerClick = () => {
    createCustomer();
  };


  const eid = localStorage.getItem('default');

  const createCustomer = (mailingAddress, serviceAddress) => {
    const payload = {

      first_name: firstName,
      last_name: lastName,
      company: company,
      phone: phone,
      email: email,

      mailing_address: mailingAddress?.line1 || '',
      mailing_street1: mailingAddress?.line1 || '',
      mailing_street2: mailingAddress?.line2 || '',
      mailing_city: mailingAddress?.city || '',
      mailing_state: mailingAddress?.region || '',
      mailing_zip: mailingAddress?.postalCode || '',

      service_address: serviceAddress?.line1 || '',
      service_street1: serviceAddress?.line1 || '',
      service_street2: serviceAddress?.line2 || '',
      service_city: serviceAddress?.city || '',
      service_state: serviceAddress?.region || '',
      service_zip: serviceAddress?.postalCode || '',



      eid: eid,
      endpoint: "v1/customer/add",
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {
          const Notificationpayload = {
            response: true,
            subject: "Customer",
            operation: "Created",
            context: `User "${firstName} ${lastName}" has been created`,

          };

          showNotification(Notificationpayload);
          setTriggerFetch(prev => prev + 1);

          setDefaultCustomerNonce(data.response.nonce);
          //  setCustomerNttNonce(data.response.nonce);

          console.log("this is the nonce ", data.response.nonce)
          if (createSale) {
            handleNewOrderClick();
          } else {
            navigate('/app/customers');
          }
        } else {
          const Notificationpayload = {
            response: false,
            subject: "Customer",
            operation: "Creating",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Customer",
          operation: "Creating",
        };

        showNotification(Notificationpayload);
      });

  }
  const allFieldsFilled = () => {
    return (
      firstName &&
      lastName &&
      company &&
      phone &&
      email &&
      // mailing_address &&
      mailing_street1 &&
      // mailing_street2 &&
      mailing_city &&
      mailing_state &&
      mailing_zip
    );
  };


  const [activeTab, setActiveTab] = React.useState("contact");



  // create a sale => select customer
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(null);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      setSelectedCustomerIndex(null);
    } else {
      setSelectedCustomerIndex(value);
    }
  };

  const handleCancelCustomer = () => {
    setSelectedCustomerIndex(null);
  };



  const [productPopup, setProductPopup] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  const fetchProductDetails = (productNonce) => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "product";
    const id = productNonce;

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }
    const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id + "&nonce=" + nonce;

    setIsLoading(true);

    fetch(url)
      .then(response => response.json())
      .then(details => {
        if (details.success) {
          setProductDetails(details.data.response[0]);
          setProductPopup(true);
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function ProductPopup({ details, onClose }) {
    // details.product.title
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-10">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h1 className="text-lg text-black font-semibold mb-4">Product Details:</h1>
          <p className="text-black text-base">Title: {details.product.title}</p>
          <p className="text-black text-base">Price: {details.properties.use_price}</p>
          <p className="text-black text-base">Short description: {details.product.short_description}</p>
          <p className="text-black text-base">Long description: {details.product.long_description}</p>
          <p className="text-black text-base">Publish Status: {details.product.publish_status}</p>

          <p className="text-black text-base">Created: {details.product.created_date}</p>
          <p className="text-black text-base">Published: {details.product.published_date}</p>
          <p className="text-black text-base">Updated: {details.product.updated_date}</p>


          <div className="flex justify-end mt-6">
            <button
              className="py-2 px-4 bg-gray-500 text-white rounded text-base hover:bg-gray-700"
              onClick={onClose}
            >
              Close
            </button>
          </div>

        </div>
      </div>
    );
  }



  const fetchCustomerDetails = (customerNonce) => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "customer";
    const id = customerNonce;

    const url = `${protocolpluspath()}admin/getData.php?eid=${eid}&type=${type}&id=${id}&nonce=${nonce}`;

    setLoadingCustomers(true);

    fetch(url)
      .then(response => response.json())
      .then((data) => {
        if (data.success && data.data.success && data.data.count > 0) {
          const customer = data.data.response[0].customer;
          setCustomerDetails({
            first_name: customer.first_name,
            last_name: customer.last_name,
            email: customer.email,
            customer_number: customer.customer_number,
            mailing_street1: customer.mailing_street1,
            mailing_city: customer.mailing_city,
            mailing_state: customer.mailing_state,
            mailing_zip: customer.mailing_zip,
            service_street1: customer.service_street1,
            service_city: customer.service_city,
            service_state: customer.service_state,
            service_zip: customer.service_zip
          });
          setUserDetails(null);

        } else {
          setCustomerDetails(null);
        }
      })
      .catch(error => {
        console.error("Error fetching customer details:", error);
        setCustomerDetails(null);
      })
      .finally(() => {
        setLoadingCustomers(false);
      });
  };

  const [customerDetails, setCustomerDetails] = useState(null);

  const [loadingOrder, setLoadingOrder] = useState(false);

  const [userDetails, setUserDetails] = useState(null);
  const [userAddressDetails, setUserAddressDetails] = useState(null);
  const [loadingOrderButton, setLoadingOrderButton] = useState(false);

  const handleSubmitOrder = () => {
    const eid = localStorage.getItem('default');

    setLoadingOrderButton(true);

    let details;
    if (customerDetails) {
      details = customerDetails;
    } else if (userDetails) {
      details = userDetails;
    } else {
      console.error("No customer or user details found");
      return;
    }

    const payload = {
      site_id: "mmobile",
      order_id: "",
      order_amount: "",
      order_date: Math.floor(Date.now() / 1000),
      order_name: `${details.first_name} ${details.last_name}`,
      order_email: details.email,
      order_status: "new",
      order_first_name: details.first_name,
      order_last_name: details.last_name,
      customer_number: "100004",

      endpoint: 'v1/woo/order/add',
      eid: eid,

      items: products.map(product => ({
        sku: product.sku,
        quantity: product.qty
      }))
    };

    setLoadingOrder(true);

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {
          const order_number = data.response.order_number;

          const Notificationpayload = {
            response: true,
            subject: "Order",
            operation: "Created",
            context: `Order "${order_number}" has been created`,
          };
          showNotification(Notificationpayload);

          setIsFormVisible(true);
          setIsNewOrderFormVisible(false);

        } else {
          const Notificationpayload = {
            response: false,
            subject: "Order",
            operation: "Creating",
          };
          showNotification(Notificationpayload);
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Order",
          operation: "Creating",
        };
        showNotification(Notificationpayload);
      })
      .finally(() => {
        setLoadingOrder(false);
      });

  };

  // address logic
  const [billingAddress, setBillingAddress] = useState('Select');
  const [shippingAddress, setShippingAddress] = useState('Select');
  const [isChecked, setIsChecked] = useState(true);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    populateAddressOptions();
  }, [customerDetails, userAddressDetails]);

  const handleBillingChange = (event) => {
    const selectedFullAddress = event.target.value;

    const selectedAddress = addressOptions.find(option => option.fullAddress === selectedFullAddress) || addresses.find(address => address === selectedFullAddress);

    setBillingAddress(selectedFullAddress);

    if (isChecked) {
      setShippingAddress(selectedFullAddress);
    }

    switch (selectedFullAddress) {
      case 'Add Address':
        setShowAddressForm(true);
        break;
      case 'Select':
        console.log('Just select lol');
        break;
      default:
        if (selectedAddress) {
          console.log('Address selected:');

          let validatedAddress;

          if (typeof selectedAddress === 'string') {
            // If the selected address is a string (from the addresses array)
            const [line1, city, regionZip] = selectedAddress.split(', ');
            const [region, zipCode] = regionZip.split(' ');

            validatedAddress = {
              line1,
              city,
              region,
              postalCode: zipCode,
              country: 'US'
            };
          } else {
            validatedAddress = {
              line1: selectedAddress.line1,
              postalCode: selectedAddress.zipCode,
              city: selectedAddress.city,
              region: selectedAddress.state,
              country: 'US'
            };
          }

          const totalAmount = calculateTotalAmount(products);

          console.log('billing address selected:', validatedAddress, totalAmount);
          createTransactionAvalara(validatedAddress, totalAmount);
        }
        setShowAddressForm(false);
        break;
    }
  };



  const cancelNewAddressForm = () => {
    setShowAddressForm(false);
    setBillingAddress('Select');
    setShippingAddress('Select');
  };

  const handleShippingChange = (event) => {
    const selectedAddress = event.target.value;
    setShippingAddress(selectedAddress);
    if (selectedAddress === 'Add Address') {
      setShowAddressForm(true);
    } else if (selectedAddress === 'Select') {
      console.log('just select lol');
    } else {
      console.log('Address selected:', selectedAddress);
      setShowAddressForm(false);
    }
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setShippingAddress(billingAddress);
    }
  };

  const populateAddressOptions = () => {
    const options = [];
    if (customerDetails) {
      if (customerDetails.mailing_street1) {
        options.push({
          line1: customerDetails.mailing_street1,
          city: customerDetails.mailing_city,
          state: customerDetails.mailing_state,
          zipCode: customerDetails.mailing_zip,
          fullAddress: `${customerDetails.mailing_street1}, ${customerDetails.mailing_city}, ${customerDetails.mailing_state} ${customerDetails.mailing_zip}`
        });
      }
      if (customerDetails.service_street1) {
        options.push({
          line1: customerDetails.service_street1,
          city: customerDetails.service_city,
          state: customerDetails.service_state,
          zipCode: customerDetails.service_zip,
          fullAddress: `${customerDetails.service_street1}, ${customerDetails.service_city}, ${customerDetails.service_state} ${customerDetails.service_zip}`
        });
      }
    }

    if (userAddressDetails) {
      if (userAddressDetails.mailing_street1) {
        options.push({
          line1: userAddressDetails.mailing_street1,
          city: userAddressDetails.mailing_city,
          state: userAddressDetails.mailing_state,
          zipCode: userAddressDetails.mailing_zip,
          fullAddress: `${userAddressDetails.mailing_street1}, ${userAddressDetails.mailing_city}, ${userAddressDetails.mailing_state} ${userAddressDetails.mailing_zip}`
        });
      }
      if (userAddressDetails.service_street1) {
        options.push({
          line1: userAddressDetails.service_street1,
          city: userAddressDetails.service_city,
          state: userAddressDetails.service_state,
          zipCode: userAddressDetails.service_zip,
          fullAddress: `${userAddressDetails.service_street1}, ${userAddressDetails.service_city}, ${userAddressDetails.service_state} ${userAddressDetails.service_zip}`
        });
      }
    }

    setAddressOptions(options);
  };


  // resolveaddress logic form
  const [addresses, setAddresses] = useState([]);

  const [formData, setFormData] = useState({
    line1: "",
    line2: "",
    line3: "",
    city: "",
    region: "",
    postalCode: "",
    country: "US"
  });

  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const eid = localStorage.getItem("default");
    const payload = {
      ...formData,
      eid: eid,
      endpoint: "v1/tax/avalara/resolveaddress"
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    fetch('/admin/postData.php', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {
          const { messages, validatedAddresses, address, coordinates, resolutionQuality, taxAuthorities } = data.response;

          if (messages && messages[0].severity === "Error") {
            const Notificationpayload = {
              response: false,
              subject: "Address",
              operation: "Finding",
              context: "Address is incomplete or invalid",
              action: "Details",
              summary: messages[0].summary,
              details: messages[0].details,
            };
            console.log(messages[0].summary)
            showNotification(Notificationpayload);
          } else {
            // Success!
            const Notificationpayload = {
              response: true,
              subject: "Address",
              operation: "Found",
              context: "Address found",
              action: "Details",
              addressSent: address,
              addressDetails: validatedAddresses[0],
              avalaraDetails: {}
            };

            if (coordinates) {
              Notificationpayload.avalaraDetails.coordinates = coordinates;
            }

            if (resolutionQuality) {
              Notificationpayload.avalaraDetails.resolutionQuality = resolutionQuality;
            }

            if (taxAuthorities) {
              Notificationpayload.avalaraDetails.taxAuthorities = taxAuthorities;
            }

            setData(Notificationpayload);
            setShowAddressForm(false);
            showNotification(Notificationpayload);

            const totalAmount = calculateTotalAmount(products);
            createTransactionAvalara(validatedAddresses[0], totalAmount);

            const newAddress = `${validatedAddresses[0].line1}, ${validatedAddresses[0].city}, ${validatedAddresses[0].region} ${validatedAddresses[0].postalCode}`;
            setAddresses(prevAddresses => [...prevAddresses, newAddress]);

            setFormData({
              line1: "",
              line2: "",
              line3: "",
              city: "",
              region: "",
              postalCode: "",
              country: "US"
            });

            setBillingAddress(newAddress);
            if (isChecked) {
              setShippingAddress(newAddress);
            }
          }
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Address",
          operation: "Finding",
          context: "Address is incomplete or invalid",
          action: "Details",
        };
        showNotification(Notificationpayload);
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  // createTransaction
  const [totalTax, setTotalTax] = useState(0);
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);

  const calculateTotalAmount = (products) => {
    return products.reduce((total, product) => total + (product.use_price * product.qty), 0).toFixed(2);
  };

  useEffect(() => {
    const total = parseFloat(calculateTotalAmount(products));
    setTotalProductAmount(total);

    if (total > 100) {
      setShippingCost(0);
    } else {
      setShippingCost(9.99);
    }
  }, [products]);




  const createTransactionAvalara = (validatedAddress, totalAmount) => {

    const transactionData = {
      lines: [
        {
          number: '1',
          quantity: 1,
          amount: totalAmount,
          taxCode: 'PS081282',
          itemCode: 'Y0001',
          description: 'Yarn'
        }
      ],
      type: 'SalesInvoice',
      companyCode: 'DEFAULT',
      date: new Date().toISOString().split('T')[0],
      customerCode: 'ABC',
      purchaseOrderNo: '2024-05-15-001',
      addresses: {
        singleLocation: {
          line1: validatedAddress.line1,
          city: validatedAddress.city,
          region: validatedAddress.region,
          country: validatedAddress.country,
          postalCode: validatedAddress.postalCode
        }
      },
      commit: true,
      currencyCode: 'USD',
      description: 'Yarn'
    };

    const payload = {
      transactionData,
      eid: localStorage.getItem('default'),
      endpoint: 'v1/tax/avalara/createtransaction'
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response.id) {
          setTotalTax(data.response.totalTax);
        } else {
          const Notificationpayload = {
            response: false,
            subject: "Address",
            operation: "Selecting",
            context: "Tax calculation cannot be determined by Avalara, please Add or select a new Address",
          };
          showNotification(Notificationpayload);

          setBillingAddress('Select');
          setShippingAddress('Select');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  // -- calculates the subtotal for each product


  // Iframe logic -- buttons (add payment method)
  const [iframeVisible, setIframeVisible] = useState(false);
  const [paymentButton, setPaymentButton] = useState(true);

  const endRef = useRef(null);

  const buttonIframeVisible = () => {
    setIframeVisible(true);
    setPaymentButton(false);
  };

  const buttonCancelIframe = () => {
    setIframeVisible(false);
    setPaymentButton(true);
  }

  useEffect(() => {
    if (iframeVisible) {
      endRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [iframeVisible]);

  //skeleton MUI -- Loading effect
  const useStyles = makeStyles({
    customSkeleton: {
      marginTop: '1rem',
      border: '1px solid #D1D5DB',
      borderRadius: '0.5rem',
      overflow: 'hidden'
    }
  });
  const classes = useStyles();

  // Create a sale with a specific NTT customer (from Customers Area)
  const { orderClicked, setOrderClicked, customerNttNonce, setCustomerNttNonce } = useNavigation();


  useEffect(() => {
    if (orderClicked) {
      handleNewOrderClick();
      setOrderClicked(false);
    }
  }, [orderClicked, setOrderClicked]);
  // -- sets nonce null so the customer will not be there all the time. (it'll be null when when cance/change customer in the autocomplete)
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const autocompleteChange = (event, value) => {
    if (value) {
      if (value.type === 'customer') {
        fetchCustomerDetails(value.nonce);
      } else if (value.type === 'user') {
        fetchUserDetails(value.nonce);
      }
      setDefaultCustomer(value);
      setIsDefaultSet(true); // avoids duplicate fetch..
    } else {
      setIsDefaultSet(false);
      setCustomerNttNonce(null);
      setDefaultCustomer(null);

    }
    setAddresses([]);

    setBillingAddress('Select');
    setShippingAddress('Select');
    setTotalTax(0);
    setAddressOptions([]);
  };


  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  useEffect(() => {
    if (orders.length > 0 && totalPages < currentPage) {
      setCurrentPage(1);
    }
  }, [orders.length, totalPages, currentPage]);

  const handlePrevPage = () => setCurrentPage(currentPage - 1);
  const handleNextPage = () => setCurrentPage(currentPage + 1);

  let pageNumbers = [];
  const maxPageNumWindow = 5;
  let startPage = Math.max(currentPage - Math.floor(maxPageNumWindow / 2), 1);
  let endPage = Math.min(startPage + maxPageNumWindow - 1, totalPages);

  if (totalPages <= maxPageNumWindow) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (startPage <= 1) {
      startPage = 1;
      endPage = maxPageNumWindow;
    } else if (endPage === totalPages) {
      startPage = totalPages - (maxPageNumWindow - 1);
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  //woo user details
  const fetchUserDetails = (userNonce) => {
    const eid = localStorage.getItem("default");
    const nonce = localStorage.getItem("nonce");
    const type = "user";
    const id = userNonce;

    const url = `${protocolpluspath()}admin/getData.php?eid=${eid}&type=${type}&id=${id}&nonce=${nonce}`;

    setLoadingCustomers(true);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.data.success && data.data.count > 0) {
          const user = data.data.response[0].user;
          setUserDetails({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            customer_number: user.customer_number
          });
          setCustomerDetails(null);

          userLookup(user.userid, user.email); // passing the email and userid to userlookup
        } else {
          setUserDetails(null);
        }
      })
      .catch(error => {
        console.error("Error fetching user details:", error);
        setUserDetails(null);
      })
      .finally(() => {
        setLoadingCustomers(false);
      });
  };


  // user lookup
  // -- loading the address (select)
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);

  const userLookup = (user_userid, user_email) => {
    const eid = localStorage.getItem("default");
    const type = "user_lookup";
    const site_id = "mmobile";
    const email = user_email;
    const userid = user_userid;

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }

    setIsLoadingAddresses(true);

    const url =
      protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&site_id=" + site_id + "&email=" + email + "&userid=" + userid;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const response = data.data.response;
        if (data.success && data.data.success && response && response.mailing_street1) {
          const response = data.data.response;
          setUserAddressDetails({
            mailing_street1: response.mailing_street1 || '',
            mailing_city: response.mailing_city || '',
            mailing_state: response.mailing_state || '',
            mailing_zip: response.mailing_zip || '',
            service_street1: response.service_street1 || '',
            service_city: response.service_city || '',
            service_state: response.service_state || '',
            service_zip: response.service_zip || ''
          });

        } else {
          console.log("else");
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoadingAddresses(false);
      });
  };

  // create a sale checkbox
  const [createSale, setCreateSale] = useState(true);


  // stepper to verify Mailing address
  const [createCustomerLoading, setCreateCustomerLoading] = useState(false);

  const mailingAndServiceAddress = () => {
    const eid = localStorage.getItem('default');

    const payload = {

      mailing_line1: mailing_street1,
      mailing_line2: mailing_street2,
      mailing_city: mailing_city,
      mailing_region: mailing_state,
      mailing_postalCode: mailing_zip,
      mailing_country: "US",

      service_line1: service_street1,
      service_line2: service_street2,
      service_city: service_city,
      service_region: service_state,
      service_postalCode: service_zip,
      service_country: "US",


      eid: eid,
      endpoint: 'v1/tax/avalara/resolve/mailing/and/service/address',
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    setCreateCustomerLoading(true);

    fetch('/admin/postData.php', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.response) {

          const mailingMessages = data?.response?.mailing_result?.messages;
          const serviceMessages = data?.response?.service_result?.messages;

          const hasMailingError = mailingMessages && mailingMessages.length > 0 && mailingMessages[0].severity === "Error";
          const hasServiceError = serviceMessages && serviceMessages.length > 0 && serviceMessages[0].severity === "Error";

          if (hasMailingError || hasServiceError) {
            let Notificationpayload;
            if (hasMailingError) {
              const errorMessages = mailingMessages[0];
              Notificationpayload = {
                response: false,
                subject: "Mailing Address",
                operation: "Finding",
                context: "Address is incomplete or invalid",
                action: "Details",
                summary: errorMessages.summary,
                details: errorMessages.details,
              };
              setActiveTab("mailing");
            } else if (hasServiceError) {
              const errorMessages = serviceMessages[0];
              Notificationpayload = {
                response: false,
                subject: "Service Address",
                operation: "Finding",
                context: "Address is incomplete or invalid",
                action: "Details",
                summary: errorMessages.summary,
                details: errorMessages.details,
              };
              setActiveTab("service-address");
            }
            showNotification(Notificationpayload);
          } else {
            // success!

            const validatedMailingAddresses = data.response.mailing_result?.validatedAddresses || [];
            const validatedServiceAddresses = data.response.service_result?.validatedAddresses || [];

            console.log(validatedMailingAddresses, validatedServiceAddresses);

            createCustomer(validatedMailingAddresses[0], validatedServiceAddresses[0]);
          }
        }
      })
      .catch(() => {
        const Notificationpayload = {
          response: false,
          subject: "Address",
          operation: "Finding",
          context: "Mailing Address is incomplete or invalid",
          action: "Details",
        };
        setActiveTab("mailing");
        showNotification(Notificationpayload);
      })
      .finally(() => {
        setCreateCustomerLoading(false);
      });

  }


  function cancelCreateCustomerForm() {
    setIsFormVisible(true);

    setFirstName('');
    setLastName('');
    setCompany('');
    setPhone('');
    setEmail('');

    setMailing_address('');
    setMailing_street1('');
    setMailing_street2('');
    setMailing_city('');
    setMailing_state('');
    setMailing_zip('');

    setService_address('');
    setService_street1('');
    setService_street2('');
    setService_city('');
    setService_state('');
    setService_zip('');
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-screen"
      style={{
        backgroundImage: 'url("https://5ca93112550f8dcf11d2-5a9e76d0467760b267bf96203a242b5e.ssl.cf1.rackcdn.com/MM_BG.png")',
        backgroundSize: 'cover'
      }}
    >
      {isFormVisible && (
        <div className="flex flex-col flex-grow overflow-hidden">

          <div className="flex justify-between items-center p-4">
            <div>
              <h1 className="text-2xl font-bold">Orders</h1>
              <p>Create and view recent orders.</p>
            </div>

            <div className="flex items-center space-x-4">
              <button className="add-new-token" onClick={handleNewCustomerClick}>
                <AddnewIcon className="mr-1" />
                Create New Customer
              </button>

              <button className="add-new-token btn" onClick={handleNewOrderClick}>
                Create Sale
              </button>
            </div>
          </div>


          <div className="flex-grow overflow-auto bg-white rounded-lg shadow-2xl m-3">

            <div className="bg-gray-100 p-12 border-b border-gray-400">
              <h1 className="text-xl font-bold mb-4">Order Search</h1>

              <div className="flex flex-col space-y-2">
                <div className="flex justify-start">
                  <input
                    type="text"
                    className="flex-grow rounded-full text-sm bg-white px-9 py-3 mr-4 focus:outline-none focus:ring-0"
                    placeholder="Order Number, Name, Email.."
                    style={{ border: 'none' }}
                    value={searchOrder}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>


            {isLoadingOrders ? (
              <div className="p-4">
                <div className="mt-4 border-gray-300 rounded-lg overflow-hidden">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      sx={{ bgcolor: 'grey.350' }}
                      variant="rectangular"
                      width={max}
                      height={105}
                      className={classes.customSkeleton}
                    />
                  ))}
                </div>
              </div>
            ) : message ? (
              <div className="flex flex-col items-center justify-center h-64">
                <NoDataIcon />
                <div className="text-lg font-semibold text-gray-600">{message}</div>
              </div>
            ) : (
              <div className="p-4">
                {currentOrders.map((order, index) => (

                  <div key={index} className="mt-4 border border-gray-300 rounded-lg overflow-hidden">

                    <table className="w-full table-fixed text-sm text-left text-gray-50 rounded-lg  ">

                      <thead className="text-xs text-gray-700 bg-gray-100 rounded-t-lg">
                        <tr>
                          <th colSpan={6}  >
                            <p
                              className="py-3 px-6 text-sm text-[#4C7021] hover:underline cursor-pointer hover:text-[#4C7021]"
                              onClick={() => openModal(order.order)}
                            >
                              Order Number:{order.order.order_id}
                            </p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="py-4 px-6 w-1/6 text-center">
                            <p className="text-black"> Customer Name:</p>
                            <p className="font-semibold text-black">{order.order.order_name || "--"}</p>
                          </td>
                          <td className="w-1/6 text-center">
                            <p className="text-black">Order Date:</p>
                            <p className="font-semibold text-black"> {order.order.order_date || "--"}</p>
                          </td>
                          <td className="w-1/6 text-center">
                            <p className="text-black">Order Amount:</p>
                            <p className="font-semibold text-black truncate">{order.order.order_amount || "--"}</p>
                          </td>
                          <td className="w-1/6 text-center">
                            <p className="text-black">Email:</p>
                            <p className="font-semibold text-black truncate">{order.order.order_email || "--"}</p>
                          </td>

                          <td className="w-1/6 text-center">
                            <p className="text-black">Status:</p>
                            <p className={`font-semibold truncate ${statusColors[order.order.order_status] || "text-gray-500"}`}>{order.order.order_status}</p>
                          </td>

                          <td className="w-1/6 text-center">
                            <a
                              className="bg-moultrieYellow text-black font-semibold py-2 px-6 cursor-pointer rounded-lg hover:bg-yellow-500"
                              href={`https://radial-moultrie-mobile.pantheonsite.io/wp-admin/post.php?post=${order.order.order_id}&action=edit`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              WooCommerce
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                ))}

                {/* pagination */}
                {orders.length > 0 && (
                  <div className="flex justify-center items-center space-x-1 my-8">
                    <button
                      onClick={() => setCurrentPage(1)}
                      disabled={currentPage === 1}
                      className="px-2 py-1 text-sm rounded text-white bg-[#4C7021] hover:[#19250c] disabled:bg-gray-400"
                    >
                      &laquo; First
                    </button>

                    <button
                      onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
                      disabled={currentPage === 1}
                      className="px-3 py-1 text-sm rounded text-white bg-[#4C7021] hover:[#19250c] disabled:bg-gray-400"
                    >
                      &lt;
                    </button>

                    {pageNumbers.map(number => (
                      <button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`px-2 py-1 text-sm rounded ${currentPage === number ? 'bg-[#4C7021] text-white' : 'bg-[#152901] text-white'} hover:[#152901] hover:text-white`}
                      >
                        {number}
                      </button>
                    ))}

                    <button
                      onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}
                      disabled={currentPage === totalPages}
                      className="px-3 py-1 text-sm rounded text-white bg-[#4C7021] hover:[#19250c] disabled:bg-gray-400"
                    >
                      &gt;
                    </button>

                    <button
                      onClick={() => setCurrentPage(totalPages)}
                      disabled={currentPage === totalPages}
                      className="px-2 py-1 text-sm rounded text-white bg-[#4C7021] hover:[#19250c] disabled:bg-gray-400"
                    >
                      Last &raquo;
                    </button>
                  </div>
                )}

                <OrderModal order={currentOrder} isOpen={modalIsOpen} onRequestClose={closeModal} />
              </div>
            )}
          </div>

        </div>
      )}
      {isNewOrderFormVisible && (
        // Create A Sale form
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center w-full min-h-screen "
        >
          <div className="flex flex-col flex-grow overflow-hidden">

            <div className="flex justify-between items-center p-4">
              <div>
                <h1 className="text-2xl font-bold">Create New Sale</h1>
                <p>Create new sales.</p>
              </div>

              <div className="flex items-center space-x-4">

                <button
                  className="cancel-button text-sm font-extrabold  text-white py-2 px-4 rounded"
                  onClick={handleCancel}>
                  Cancel
                </button>

              </div>
            </div>


            <div className="flex-grow overflow-auto bg-white rounded-lg shadow-2xl m-3">

              <div className="bg-gray-100 p-12 border-b border-gray-400">

                <div className="flex items-center p-4">
                  <h1 className="text-xl font-bold">Add Sale To:</h1>
                  {selectedCustomerIndex !== null ? (
                    <div className="flex items-center ml-4">
                      <h1 className="text-xl font-bold hover:underline cursor-pointer text-blue-500 hover:text-blue-700">
                        {customers[selectedCustomerIndex].first_name} {customers[selectedCustomerIndex].last_name}
                      </h1>
                      <div onClick={handleCancelCustomer} className="cursor-pointer ml-8" style={{ marginTop: '3px' }}>
                        <DeleteIconx />
                      </div>
                    </div>

                  ) : (

                    <Autocomplete
                      disablePortal
                      options={names}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, value) => {
                        autocompleteChange(event, value);
                        if (value) {
                          console.log(value.type);
                        }
                      }}
                      loading={loadingCustomers}
                      loadingText="Loading..."
                      inputValue={searchValue}
                      onInputChange={(event, newValue) => setSearchValue(newValue)}
                      value={defaultCustomer}
                      sx={{
                        width: 500,
                        paddingLeft: "15px",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        }
                      }}
                      renderOption={(props, item) => (
                        <li {...props} key={item.nonce}>
                          {item.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customers"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex justify-start">
                    <input
                      type="text"
                      className="flex-grow rounded-full text-sm bg-white px-9 py-3 mr-4 focus:outline-none focus:ring-0"
                      placeholder="SKU Number"
                      value={newProduct.sku}
                      onChange={handleSKUChange}
                      style={{ border: 'none' }}
                    />
                    <input
                      type="number"
                      className="rounded-full text-sm bg-white px-5 py-3 mr-4 focus:outline-none focus:ring-0"
                      placeholder="Quantity"
                      value={newProduct.qty}
                      onChange={handleQuantityChange}
                      style={{ border: 'none', width: '150px' }}
                    />

                    <LoadingButton
                      onClick={handleAddButtonClick}
                      loading={loadingProductButton}
                      loadingIndicator="Loading…"
                      variant="contained"
                      disabled={!newProduct.sku || newProduct.qty <= 0}
                      sx={{
                        backgroundColor: '#4C7021',
                        color: 'black',
                        fontWeight: 550,
                        '&:hover': {
                          backgroundColor: '#3c591a',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#ffc33e',
                        },
                      }}
                    >
                      <span>Add Product</span>
                    </LoadingButton>

                  </div>
                </div>

              </div>

              <div className="p-4">

                <h1 className="text-xl font-bold mb-4">Items Ordered</h1>
                <div className="mt-4 border border-gray-300 rounded-lg overflow-hidden">
                  <table className="w-full table-fixed text-sm text-left text-gray-50 rounded-lg">
                    <thead className="text-xs text-gray-700 bg-gray-100 rounded-t-lg">
                      <tr>
                        <th className="py-3 px-6 text-sm text-black text-center">Product</th>
                        <th className="py-3 px-6 text-sm text-black text-center">Price</th>
                        <th className="py-3 px-6 text-sm text-black text-center">QTY</th>
                        <th className="py-3 px-6 text-sm text-black text-center">Subtotal</th>
                        <th className="py-3 px-6 text-sm text-black text-center">SKU</th>
                        <th className="py-3 px-6 text-sm text-black text-center">Row Subtotal</th>
                        <th className="py-3 px-6 text-sm text-black text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {products.map((product) => {
                        const subtotal = product.use_price * product.qty;
                        return (
                          <tr key={product.id} className="border-b border-gray-300">
                            <td className="py-4 px-6 text-center">
                              <p
                                onClick={() => { fetchProductDetails(product.nonce); }}
                                className="text-blue-500 hover:underline cursor-pointer hover:text-blue-700">
                                {product.title}
                              </p>
                            </td>
                            <td className="py-4 px-6 text-center">
                              <p className="text-black ">{product.use_price}</p>
                            </td>
                            <td className="py-4 px-6 text-center">
                              <p className="text-black">{product.qty}</p>
                            </td>
                            <td className="py-4 px-6 text-center">
                              <p className="text-black">{subtotal.toFixed(2)}</p>
                            </td>
                            <td className="py-4 px-6 text-center">
                              <p className="text-black">{product.sku}</p>
                            </td>
                            <td className="py-4 px-6 text-center">
                              <p className="text-black">--</p>
                            </td>
                            <td className="py-4 px-6 text-center">
                              <IconButton
                                onClick={() => handleDeleteProduct(product.id)}
                                aria-label="delete" size="large">
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}

                      {productPopup && productDetails && <ProductPopup details={productDetails} onClose={() => setProductPopup(false)} />}



                      {products.length === 0 ? (
                        < p style={{ padding: '20px' }} className="py-3 px-6 text-black ">No Ordered Items.</p>
                      ) : (
                        <tr>
                          <th className="py-3 px-6 text-sm text-black text-center">Subtotal: {totalProductAmount.toFixed(2)}</th>
                          <th className="py-3 px-6 text-sm text-black text-center">Tax: {totalTax.toFixed(2)}</th>
                          <th className="py-3 px-6 text-sm text-black text-center">Shipping: {shippingCost === 0 ? 'Free' : `${shippingCost.toFixed(2)}`}</th>
                          <th className="py-3 px-6 text-sm text-black text-center">Total: {(totalProductAmount + totalTax + shippingCost).toFixed(2)}</th>
                        </tr>
                      )}



                    </tbody>

                  </table>
                </div>

                {isLoading &&
                  < div className="flex flex-col items-center justify-center h-64 z-10">
                    <div className="z-10">Loading...</div>
                  </div>
                }

              </div>

              <div className="p-4">

                <h1 className="text-xl font-bold mb-4">Address Information</h1>
                <div className="flex justify-between">
                  <div className="flex-1 mr-4">
                    <h1 className="font-bold text-normal mb-4">Billing Address</h1>

                    {isLoadingAddresses ? (
                      <select style={{ marginTop: '11px' }} className="w-full input-style" disabled>
                        <option value="Select">loading...</option>
                      </select>
                    ) : (
                      <select
                        className="w-full input-style"
                        style={{ marginTop: '11px' }}
                        value={billingAddress}
                        onChange={handleBillingChange}
                        disabled={!defaultCustomer}
                      >
                        <option value="Select">Select</option>
                        {[...addressOptions, ...addresses].map((address, index) => (
                          <option key={index} value={address.fullAddress || address}>
                            {address.fullAddress || address}
                          </option>
                        ))}


                        <option value="Add Address">Add Address</option>
                      </select>

                    )}
                  </div>
                  <div className="flex-1">
                    <div className="flex flex-col">
                      <div className="flex items-center mb-2">
                        <h1 className="font-bold text-normal mr-2">Shipping Address</h1>
                        <div className="flex items-center">
                          <p className="text-sm">Same as Billing Address</p>
                          <Checkbox checked={isChecked} onChange={toggleCheckbox} />
                        </div>
                      </div>

                      {isLoadingAddresses ? (
                        <select className="w-full input-style" disabled>
                          <option value="Select">loading...</option>
                        </select>
                      ) : (
                        <select
                          className="w-full input-style"
                          value={shippingAddress}
                          onChange={handleShippingChange}
                          disabled={isChecked || !defaultCustomer}
                        >
                          <option value="Select">Select</option>
                          {/* {[...addressOptions, ...addresses].map((address, index) => (
                            <option key={index} value={address.fullAddress}>
                              {address.fullAddress}
                            </option>
                          ))} */}

                          <option value="Add Address">Add Address</option>
                        </select>

                      )}

                    </div>
                  </div>
                </div>


                {showAddressForm && (
                  <div className="mt-4 p-4 border rounded">
                    <Container maxWidth="sm">
                      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="Street 1"
                              name="line1"
                              value={formData.line1}
                              onChange={handleChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="Street 2"
                              name="line2"
                              value={formData.line2}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                        {/* <TextField
                          fullWidth
                          margin="normal"
                          label="Line 3"
                          name="line3"
                          value={formData.line3}
                          onChange={handleChange}
                        /> */}

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="City"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>

                            <TextField
                              fullWidth
                              margin="normal"
                              label="State"
                              name="region"
                              value={formData.region}
                              onChange={handleChange}
                              required
                            />
                          </Grid>
                        </Grid>

                        <TextField
                          fullWidth
                          margin="normal"
                          label="Zip"
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={handleChange}
                          required
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              onClick={() => cancelNewAddressForm()}
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: 2,
                                backgroundColor: 'grey',
                                ':hover': {
                                  backgroundColor: 'darkgrey',
                                },
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              sx={{ mt: 2 }}
                            >
                              Add Address
                            </Button>
                          </Grid>
                        </Grid>

                      </Box>
                    </Container>
                  </div>
                )}
              </div>

              {paymentButton && (
                <div className="flex justify-end pr-4">

                  <LoadingButton
                    onClick={buttonIframeVisible}
                    loadingIndicator="Loading…"
                    variant="contained"
                    disabled={
                      !defaultCustomer ||
                      products.length === 0 ||
                      billingAddress === 'Select' ||
                      shippingAddress === 'Select' ||
                      billingAddress === 'Add Address' ||
                      shippingAddress === 'Add Address'
                    }
                    sx={{
                      backgroundColor: '#4C7021',
                      color: 'black',
                      fontWeight: 550,
                      '&:hover': {
                        backgroundColor: '#3c591a',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#ffc33e',
                      },
                    }}
                  >
                    <span>Add Payment Method</span>
                  </LoadingButton>

                </div>
              )}

              {iframeVisible && (
                <div className="flex flex-col items-center justify-center min-h-screen">
                  <div ref={endRef} className="w-full max-w-4xl">

                    <div className="flex justify-between items-center pr-2 mt-4">
                      <div className="flex items-center">
                        {loadingOrder && (
                          <div className="mr-2">Loading...</div>
                        )}
                      </div>

                      <div className="flex gap-2">

                        <LoadingButton
                          onClick={handleSubmitOrder}
                          loading={loadingOrderButton}
                          loadingIndicator="Loading…"
                          variant="contained"
                          sx={{
                            backgroundColor: '#ffc33e',
                            color: 'black',
                            fontWeight: 550,
                            '&:hover': {
                              backgroundColor: '#dba735',
                            },
                          }}
                        >
                          Submit Order
                        </LoadingButton>

                        <button
                          className="cancel-button text-sm font-semibold text-white py-2 px-4 rounded"
                          onClick={buttonCancelIframe}
                        >
                          Cancel
                        </button>

                      </div>

                    </div>

                    <iframe
                      title="eProtect Payment"
                      src="https://dev-api.ntt.tools/v1/eprotect/init?id=4afe11bb202a4ae9857da7f2bf5660e3&total_amount=1&order_id=54321"
                      width="100%"
                      height="900"
                      frameBorder="0"
                      allowFullScreen
                    // className="rounded-lg shadow-lg"
                    ></iframe>

                  </div>
                </div>
              )}


            </div>




          </div>



        </motion.div>
      )
      }
      {
        !isFormVisible && !isNewOrderFormVisible && (
          // New Customer form
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex justify-center w-full min-h-screen pt-10"
          >

            <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">
              <div className="">
                <div>
                  <div className="flex justify-between items-center py-6">
                    <div>
                      <h1 className="token-title">Create a Customer</h1>
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            size="small"
                            defaultChecked
                            checked={createSale}
                            onChange={() => setCreateSale(!createSale)}
                          />}
                        label="Automatically create a Sale after creating the customer"
                        labelPlacement="end"
                      />
                    </div>

                    {/* <button onClick={handleCreateCustomerClick} className="save-and-continue text-sm font-semibold mr-2">Save</button> */}

                    <div className="flex">
                      <LoadingButton
                        onClick={mailingAndServiceAddress}
                        className={`save-and-continue text-sm font-semibold mr-2 ${createCustomerLoading ? 'loading' : ''}`}
                        loading={createCustomerLoading}
                        loadingIndicator="Loading…"
                        variant="contained"
                        disabled={!allFieldsFilled()}
                        sx={{
                          backgroundColor: '#4C7021',
                          color: 'white',
                          fontWeight: 550,
                          '&:hover': {
                            backgroundColor: '#3c591a',
                          },
                          '&.Mui-disabled': {
                            backgroundColor: '#ffc33e',
                          },
                          '&.loading': {
                            width: '100px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                          },
                        }}
                      >
                        <span>Save</span>
                      </LoadingButton>

                      <button
                        className="cancel-button text-sm font-semibold text-white py-2 px-4 rounded ml-2"
                        onClick={() => cancelCreateCustomerForm()}
                      >
                        Cancel
                      </button>
                    </div>


                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">First Name</label>
                      <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="---"
                        className="w-full input-style"
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">Last Name</label>
                      <input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="---"
                        className="w-full input-style"
                      />
                    </div>
                  </div>


                  {/* Username and Password */}
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">Company</label>
                      <input
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="---"
                        className="w-full input-style"
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      {/* <label className="text-sm font-semibold">Customer Number</label>
                    <input
                      value={customerNumber}
                      onChange={(e) => setCustomerNumber(e.target.value)}
                      placeholder="---"
                      className="w-full input-style"
                    /> */}
                    </div>
                  </div>

                  <div className="flex justify-center mt-4 border-b border-gray-300">
                    <button
                      className={`py-2 px-4 ${activeTab === "contact" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                      onClick={() => setActiveTab("contact")}
                    >
                      Contact
                    </button>
                    <button
                      className={`py-2 px-4 ml-2 ${activeTab === "mailing" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                      onClick={() => setActiveTab("mailing")}
                    >
                      Mailing Address
                    </button>

                    <button
                      className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                      onClick={() => setActiveTab("service-address")}
                    >
                      Service Address
                    </button>

                  </div>

                  <div className="mt-4">
                    {activeTab === "contact" && (
                      <div>
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex-1 mr-2">
                            <label className="text-sm font-semibold">Phone Number:</label>
                            <input
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              placeholder="---"
                              className="w-full input-style"
                              type="text"
                            />
                          </div>

                          <div className="flex-1 ml-2">
                            <label className="text-sm font-semibold">Email:</label>
                            <input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="---"
                              className="w-full input-style"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "mailing" && (
                      <div>
                        <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                        <input
                          value={mailing_address}
                          onChange={(e) => setMailing_address(e.target.value)}
                          placeholder="---"
                          className="mb-2 w-full input-style"
                          type="text"
                        />

                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                              <label className="text-sm font-semibold">Street 1:</label>
                              <input
                                value={mailing_street1}
                                onChange={(e) => setMailing_street1(e.target.value)}
                                placeholder="---"
                                className="w-full input-style"
                                type="text"
                              />
                            </div>

                            <div className="flex-1 ml-2">
                              <label className="text-sm font-semibold">Street 2:</label>
                              <input
                                value={mailing_street2}
                                onChange={(e) => setMailing_street2(e.target.value)}
                                placeholder="---"
                                className="w-full input-style"
                                type="text"
                              />
                            </div>


                          </div>
                        </div>

                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                              <label className="text-sm font-semibold">City:</label>
                              <input
                                value={mailing_city}
                                onChange={(e) => setMailing_city(e.target.value)}
                                placeholder="---"
                                className="w-full input-style"
                                type="text"
                              />
                            </div>

                            <div className="flex-1 ml-2">
                              <label className="text-sm font-semibold">State:</label>

                              <select
                                value={mailing_state}
                                onChange={(e) => setMailing_state(e.target.value)}
                                className="mb-2 w-full input-style cursor-pointer">
                                <option value="">---</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>

                            </div>
                          </div>
                        </div>

                        <label className="text-sm font-semibold">Zip:</label>
                        <input
                          value={mailing_zip}
                          onChange={(e) => setMailing_zip(e.target.value)}
                          placeholder="---"
                          className="mb-2 w-full input-style"
                          type="text"
                        />
                      </div>
                    )}
                    {activeTab === "service-address" && (
                      <div>
                        <label className="text-sm font-semibold">Service Address Nickname:</label>
                        <input
                          value={service_address}
                          onChange={(e) => setService_address(e.target.value)}
                          placeholder="---"
                          className="mb-2 w-full input-style"
                          type="text"
                        />

                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                              <label className="text-sm font-semibold">Street 1:</label>
                              <input
                                value={service_street1}
                                onChange={(e) => setService_street1(e.target.value)}
                                placeholder="---"
                                className="w-full input-style"
                                type="text"
                              />
                            </div>

                            <div className="flex-1 ml-2">
                              <label className="text-sm font-semibold">Street 2:</label>
                              <input
                                value={service_street2}
                                onChange={(e) => setService_street2(e.target.value)}
                                placeholder="---"
                                className="w-full input-style"
                                type="text"
                              />
                            </div>


                          </div>
                        </div>

                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                              <label className="text-sm font-semibold">City:</label>
                              <input
                                value={service_city}
                                onChange={(e) => setService_city(e.target.value)}
                                placeholder="---"
                                className="w-full input-style"
                                type="text"
                              />
                            </div>

                            <div className="flex-1 ml-2">
                              <label className="text-sm font-semibold">State:</label>
                              <select
                                value={service_state}
                                onChange={(e) => setService_state(e.target.value)}
                                className="mb-2 w-full input-style cursor-pointer">
                                <option>---</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <label className="text-sm font-semibold">Zip:</label>
                        <input
                          value={service_zip}
                          onChange={(e) => setService_zip(e.target.value)}
                          placeholder="---"
                          className="mb-2 w-full input-style"
                          type="text"
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>

          </motion.div >
        )
      }
    </motion.div >
  );
}
