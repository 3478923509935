import React, { createContext, useContext, useRef, useEffect, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';



const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
    const [notificationCenter, setNotificationCenter] = useState(null);

    useEffect(() => {
        const nc = new NotificationCenter();
        setNotificationCenter(nc);

    }, []);

    const showNotification = (params) => {
        if (notificationCenter) {
            notificationCenter.spawnNoteWithParams(params);
        }
    };

    class NotificationCenter {
        constructor() {
            this.items = [];
            this.itemsToKill = [];
            this.shownMessages = new Set();
            this.killTimeout = null;
            this.currentIndex = 0;
        }

        spawnNoteIfNeeded() {
            this.spawnNote();
        }

        spawnNote() {
            if (this.currentIndex >= this.messages.length) {
                this.currentIndex = 0;
            }

            if (this.items.length >= 3) {
                const firstNoteId = this.items[0].id;
                this.killNote(firstNoteId, { target: { getAttribute: () => firstNoteId } });
            }

            const message = this.messages[this.currentIndex];
            this.shownMessages.add(message.id);
            this.currentIndex++;

            const id = this.random(0, 2 ** 32, true).toString(16);
            const note = new Notification({
                id: `note-${id}`,
                icon: message.icon,
                title: message.title,
                context: message.context,
                actions: message.actions,

            });
            const transY = 100 * this.items.length;

            note.el.style.transform = `translateY(${transY}%)`;
            note.el.addEventListener("click", this.killNote.bind(this, note.id));

            this.items.push(note);
        }

        spawnNotes(amount) {
            let count = typeof amount === "number" ? amount : this.random(1, 5, true);

            while (count--)
                this.spawnNote();
        }

        killNote(id, e) {
            const note = this.items.find(item => item.id === id);
            const tar = e.target;

            if (note && tar.getAttribute("data-dismiss") === id) {
                note.el.classList.add("notification--out");
                this.itemsToKill.push(note);

                clearTimeout(this.killTimeout);

                this.killTimeout = setTimeout(() => {
                    this.itemsToKill.forEach(itemToKill => {
                        document.body.removeChild(itemToKill.el);

                        const left = this.items.filter(item => item.id !== itemToKill.id);
                        this.items = [...left];
                    });

                    this.itemsToKill = [];

                    this.shiftNotes();
                }, note.killTime);
            }
        }

        shiftNotes() {
            this.items.forEach((item, i) => {
                const transY = 100 * i;
                item.el.style.transform = `translateY(${transY}%)`;
            });
        }

        random(min, max, round = false) {
            const percent = crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
            const relativeValue = (max - min) * percent;

            return min + (round === true ? Math.round(relativeValue) : +relativeValue.toFixed(2));
        }

        spawnNoteWithParams(params) {
            if (this.items.length >= 3) {
                const firstNoteId = this.items[0].id;
                this.killNote(firstNoteId, { target: { getAttribute: () => firstNoteId } });
            }

            const { icon, title, context, actions, summary, details, addressSent, addressDetails, avalaraDetails, response } = this.constructMessageFromParams(params);
            const id = this.random(0, 2 ** 32, true).toString(16);

            const note = new Notification({
                id: `note-${id}`,
                icon,
                title,
                context,
                actions,
                additionalAction: params.action,
                summary,
                details,
                addressSent,
                addressDetails,
                avalaraDetails,
                response
            });
            const transY = 100 * this.items.length;

            note.el.style.transform = `translateY(${transY}%)`;
            note.el.addEventListener("click", this.killNote.bind(this, note.id));

            this.items.push(note);
            document.body.appendChild(note.el);
        }

        constructMessageFromParams(params) {
            let title;
            const icon = params.response ? "success" : "error";
            const context = params.context || "";

            const actions = ["OK"];
            if (params.action) {
                actions.push(params.action);
            }

            if (params.response) {
                title = `${params.subject} ${params.operation} Successfully`;
            } else {
                title = `Error ${params.operation} ${params.subject}`;
            }

            return {
                icon,
                title,
                context,
                actions,
                summary: params.summary,
                details: params.details,
                addressSent: params.response ? params.addressSent : undefined,
                addressDetails: params.response ? params.addressDetails : undefined,
                avalaraDetails: params.response ? params.avalaraDetails : undefined,
                response: params.response
            };
        }


    }

    class Notification {
        constructor(args) {
            this.args = args;
            this.el = null;
            this.id = null;
            this.killTime = 300;
            this.init(args);
        }

        init(args) {
            const { id, icon, title, context, actions, additionalAction, summary, details, addressSent, addressDetails, avalaraDetails, response } = args;
            const block = "notification";
            const parent = document.body;
            const xmlnsSVG = "http://www.w3.org/2000/svg";
            const xmlnsUse = "http://www.w3.org/1999/xlink";

            const note = this.newEl("div");
            note.id = id;
            note.className = block;
            parent.insertBefore(note, parent.lastElementChild);

            const box = this.newEl("div");
            box.className = `${block}__box`;
            note.appendChild(box);

            const content = this.newEl("div");
            content.className = `${block}__content`;
            box.appendChild(content);

            const _icon = this.newEl("div");
            _icon.className = `${block}__icon`;
            content.appendChild(_icon);

            const iconSVG = this.newEl("svg", xmlnsSVG);
            iconSVG.setAttribute("class", `${block}__icon-svg`);
            iconSVG.setAttribute("role", "img");
            iconSVG.setAttribute("aria-label", icon);
            iconSVG.setAttribute("width", "32px");
            iconSVG.setAttribute("height", "32px");
            _icon.appendChild(iconSVG);

            const iconUse = this.newEl("use", xmlnsSVG);
            iconUse.setAttributeNS(xmlnsUse, "href", `#${icon}`);
            iconSVG.appendChild(iconUse);

            const text = this.newEl("div");
            text.className = `${block}__text`;
            content.appendChild(text);

            const _title = this.newEl("div");
            _title.className = `${block}__text-title`;
            _title.textContent = title;
            text.appendChild(_title);

            if (context) {
                const _context = this.newEl("div");
                _context.className = `${block}__text-context`;
                _context.textContent = context;
                text.appendChild(_context);
            }

            const btns = this.newEl("div");
            btns.className = `${block}__btns`;
            box.appendChild(btns);

            actions.forEach(action => {
                const btn = this.newEl("button");
                btn.className = `${block}__btn`;
                btn.type = "button";
                btn.setAttribute("data-dismiss", id);

                const btnText = this.newEl("span");
                btnText.className = `${block}__btn-text`;
                btnText.textContent = action;

                btn.appendChild(btnText);
                btns.appendChild(btn);


                if (action === additionalAction) {
                    btn.addEventListener("click", (e) => {
                        e.stopPropagation();
                        if (response) {
                            showAddressPopup(addressSent, addressDetails, avalaraDetails);
                        } else {
                            showErrorPopup(summary, details);
                        }
                    });
                }

            });

            this.el = note;
            this.id = note.id;
        }

        newEl(elName, NSValue) {
            if (NSValue)
                return document.createElementNS(NSValue, elName);
            else
                return document.createElement(elName);
        }

    }
    const [errorPopup, setErrorPopup] = useState(null);
    const [addressPopup, setAddressPopup] = useState(null);
    const showErrorPopup = (summary, details) => {
        setErrorPopup({ summary, details });
    };

    const showAddressPopup = (addressSent, addressDetails, avalaraDetails) => {
        setAddressPopup({ addressSent, addressDetails, avalaraDetails });
    };



    const ErrorPopup = ({ summary, details, onClose }) => (
        <div className="popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="popup-content bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold">Details of the error:</h2>
                <p><strong>Summary:</strong> {summary}</p>
                <p><strong>Details:</strong> {details}</p>
                <button className="popup-close mt-4 px-4 py-2 bg-red-500 text-white rounded" onClick={onClose}>Close</button>
            </div>
        </div>
    );

    const [activeTab, setActiveTab] = React.useState("address-sent");

    const AddressPopup = ({ addressSent, addressDetails, avalaraDetails, onClose }) => {
        console.log('address details received from notification context', addressDetails);
        console.log('address sent received from notification context', addressSent);

        const {
            line1: line1_sent = '',
            line2: line2_sent = '',

            city: city_sent = '',
            region: region_sent = '',
            country: country_sent = '',
            postalCode: postalCode_sent = ''
        } = addressSent || {};

        const {
            line1 = '',
            line2 = '',
            line3 = '',
            city = '',
            region = '',
            country = '',
            postalCode = ''
        } = addressDetails || {};



        const formatAvalaraDetails = (details) => {
            if (!details) return '';
            let formattedText = '';

            Object.keys(details).forEach(key => {
                if (Array.isArray(details[key])) {
                    formattedText += `${key}:\n`;
                    details[key].forEach(item => {
                        formattedText += `${JSON.stringify(item, null, 2)}\n`;
                    });
                } else if (typeof details[key] === 'object') {
                    formattedText += `${key}:\n${JSON.stringify(details[key], null, 2)}\n`;
                } else {
                    formattedText += `${key}: ${details[key]}\n`;
                }
            });

            return formattedText.replace(/"|{|}/g, '');
        };




        const buildGoogleMapsURL = () => {
            const address = `${line1}, ${line2 ? line2 + ", " : ""}${line3 ? line3 + ", " : ""}${city}, ${region}, ${country}, ${postalCode}`;
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
        };

        const [showTooltip, setShowTooltip] = useState(true);
        const scrollableDivRef = useRef(null);

        useEffect(() => {
            const handleScroll = () => {
                setShowTooltip(false);
            };

            const scrollableDiv = scrollableDivRef.current;

            if (scrollableDiv) {
                scrollableDiv.addEventListener('scroll', handleScroll);
            }

            return () => {
                if (scrollableDiv) {
                    scrollableDiv.removeEventListener('scroll', handleScroll);
                }
            };
        }, []);


        return (
            <div className="popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="popup-content bg-white p-6 rounded-lg shadow-lg">
                    <h1 className="text-xl font-bold">
                        Address Details
                    </h1>

                    <h2 className="text-base font-bold">
                        Validated Address
                        <Tooltip title="Avalaras Endpoint Found this address, it might not match the current address sent in the payload" arrow placement="right" sx={{ p: 1 }}>
                            <IconButton size="small">
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </h2>




                    <div>
                        <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                                <label className="text-sm font-semibold">Street</label>
                                <input
                                    className="w-full input-style"
                                    type="text"
                                    value={line1 || "---"}
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="flex-1 ml-2">
                                <label className="text-sm font-semibold">Street 2</label>
                                <input
                                    className="w-full input-style"
                                    type="text"
                                    value={line2 || "---"}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                                <label className="text-sm font-semibold">City</label>
                                <input
                                    className="w-full input-style"
                                    type="text"
                                    value={city || "---"}
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="flex-1 ml-2">
                                <label className="text-sm font-semibold">State</label>
                                <input
                                    className="w-full input-style"
                                    type="text"
                                    value={region || "---"}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex justify-between items-center mb-2">
                            <div className="flex-1 mr-2">
                                <label className="text-sm font-semibold">Country</label>
                                <input
                                    className="w-full input-style"
                                    type="text"
                                    value={country || "---"}
                                    readOnly
                                    disabled
                                />
                            </div>

                            <div className="flex-1 ml-2">
                                <label className="text-sm font-semibold">Zip</label>
                                <input
                                    className="w-full input-style"
                                    type="text"
                                    value={postalCode || "---"}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mt-4 border-b border-gray-300">
                        <button
                            className={`py-2 px-4 ml-2 ${activeTab === "address-sent" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                            onClick={() => setActiveTab("address-sent")}
                        >
                            Address Entered
                        </button>

                        <button
                            className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                            onClick={() => setActiveTab("service-address")}
                        >
                            Avalara Details
                        </button>

                    </div>

                    <div className="mt-4">
                        {activeTab === "address-sent" && (
                            <div>
                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <div className="flex-1 mr-2">
                                            <label className="text-sm font-semibold">Street 1</label>
                                            <input
                                                className="w-full input-style"
                                                type="text"
                                                value={line1_sent}
                                                readOnly
                                                disabled
                                            />
                                        </div>

                                        <div className="flex-1 ml-2">
                                            <label className="text-sm font-semibold">Street 2</label>
                                            <input
                                                className="w-full input-style"
                                                type="text"
                                                value={line2_sent}
                                                readOnly
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <div className="flex-1 mr-2">
                                            <label className="text-sm font-semibold">City</label>
                                            <input
                                                className="w-full input-style"
                                                type="text"
                                                value={city_sent || "---"}
                                                readOnly
                                                disabled
                                            />
                                        </div>

                                        <div className="flex-1 ml-2">
                                            <label className="text-sm font-semibold">State</label>
                                            <input
                                                className="w-full input-style"
                                                type="text"
                                                value={region_sent || "---"}
                                                readOnly
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <div className="flex-1 mr-2">
                                            <label className="text-sm font-semibold">Country</label>
                                            <input
                                                className="w-full input-style"
                                                type="text"
                                                value={country_sent || "---"}
                                                readOnly
                                                disabled
                                            />
                                        </div>

                                        <div className="flex-1 ml-2">
                                            <label className="text-sm font-semibold">Zip</label>
                                            <input
                                                className="w-full input-style"
                                                type="text"
                                                value={postalCode_sent || "---"}
                                                readOnly
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === "service-address" && (
                            <div>
                                <h2 className="text-base font-bold">Avalara Details</h2>
                                <Tooltip
                                    title="See more"
                                    open={showTooltip}
                                    placement="bottom"
                                    disableHoverListener
                                    disableFocusListener
                                    disableTouchListener
                                    PopperProps={{
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -10],
                                                },
                                            },
                                        ],
                                    }}
                                >
                                    <div
                                        className="avalara-section max-h-60 overflow-y-auto relative"
                                        ref={scrollableDivRef}
                                    >
                                        <pre className="whitespace-pre-wrap">{formatAvalaraDetails(avalaraDetails)}</pre>
                                    </div>
                                </Tooltip>
                            </div>


                        )}

                    </div>
                    <div className="flex justify-end mt-6">
                        <a
                            href={buildGoogleMapsURL()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="py-3 px-6 text-sm text-[#4C7021] hover:underline cursor-pointer hover:text-[#4C7021]"
                        >
                            Open in Google Maps
                        </a>
                        <button
                            className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    const style = document.createElement("style");
    style.textContent = `
    .popup {
      position: fixed;
      inset: 0;
      background: rgba(75, 85, 99, 0.5); /* bg-gray-600 bg-opacity-50 */
      overflow-y: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      z-index: 10;
    }
    .popup-content {
      background: white;
      padding: 20px;
      border-radius: 8px; /* rounded-lg */
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
      width: 100%;
      max-width: 32rem; /* max-w-lg */
    }
    .popup-content h1 {
      font-size: 1.125rem; /* text-lg */
      font-weight: 600; /* font-semibold */
      margin-bottom: 1rem; /* mb-4 */
    }
    .popup-close-wrapper {
      display: flex;
      justify-content: flex-end; /* justify-end */
      margin-top: 1.5rem; /* mt-6 */
    }
    .popup-close {
      padding: 0.5rem 1rem; /* py-2 px-4 */
      background-color: #6B7280; /* bg-gray-500 */
      color: white;
      border-radius: 0.25rem; /* rounded */
      cursor: pointer;
    }
    .popup-close:hover {
      background-color: #374151; /* hover:bg-gray-700 */
    }
  `;
    document.head.appendChild(style);



    const handleClosePopup = () => {
        setErrorPopup(null);
        setAddressPopup(null);
    };


    return (
        <NotificationContext.Provider value={{ showNotification }}>
            <div class="fixed top-0 left-0">
                <svg display="none">
                    <symbol id="error" viewBox="0 0 32 32" >
                        <circle r="15" cx="16" cy="16" fill="none" stroke="var(--error-color, hsl(13,90%,55%))" stroke-width="2" />
                        <line x1="10" y1="10" x2="22" y2="22" stroke="var(--error-color, hsl(13,90%,55%))" stroke-width="2" stroke-linecap="round" />
                        <line x1="22" y1="10" x2="10" y2="22" stroke="var(--error-color, hsl(13,90%,55%))" stroke-width="2" stroke-linecap="round" />
                    </symbol>
                    <symbol id="success" viewBox="0 0 32 32" >
                        <circle r="15" cx="16" cy="16" fill="none" stroke="var(--success-color, hsl(93,90%,40%))" stroke-width="2" />
                        <polyline points="9,18 13,22 23,12" fill="none" stroke="var(--success-color, hsl(93,90%,40%))" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </symbol>
                </svg>
            </div>
            {errorPopup && (
                <ErrorPopup
                    summary={errorPopup.summary}
                    details={errorPopup.details}
                    onClose={handleClosePopup}
                />
            )}
            {addressPopup && (
                <AddressPopup
                    addressDetails={addressPopup.addressDetails}
                    addressSent={addressPopup.addressSent}
                    avalaraDetails={addressPopup.avalaraDetails}
                    onClose={handleClosePopup}
                />
            )}
            {children}
        </NotificationContext.Provider>
    );
};


export const useNotifications = () => useContext(NotificationContext);