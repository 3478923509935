


export function manageAccess(data) {
    const accessArray = data.access || [];

    let canDeleteCustomer = false;


    for (const accessItem of accessArray) {




        if (accessItem.delete && accessItem.delete.subtype && accessItem.delete.subtype.customer === "1") {
            canDeleteCustomer = true;
        }
    }

    localStorage.setItem('canDeleteCustomer', canDeleteCustomer);

}
