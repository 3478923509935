import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AddnewIcon, NoDataIcon } from "../assets/icons/hiking";



import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { max } from 'moment';
import { Email } from "@mui/icons-material";


export default function Plans() {

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [orders, setOrders] = useState([]);


  useEffect(() => {
    const eid = localStorage.getItem("default");
    const type = "user_lookup";
    const site_id = "mmobile";
    const email = "foundanoreo@gmail.com";
    const userid = "18";

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }

    const url =
      protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&site_id=" + site_id + "&email=" + email + "&userid=" + userid;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);



  useEffect(() => {
    const eid = localStorage.getItem("default");
    const type = "woo_orders";

    function protocolpluspath() {
      return window.location.protocol + "//" + window.location.host + "/";
    }

    setIsLoading(true);
    setMessage('');

    const url =
      protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const sortedOrders = data.data.response.sort((a, b) => new Date(b.order.order_date) - new Date(a.order.order_date)); // i should do reverse(). 
          // Fisrt 10 items (orders)
          setOrders(sortedOrders.slice(0, 10));
        } else {
          setMessage('No Data');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage('No Data');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);


  // color of satus change based on status of the order
  const statusColors = {
    processing: "text-yellow-500",
    pending: "text-yellow-500",
    cancelled: "text-red-500",
    completed: "text-green-500"
  };

  ///popup
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  const openModal = (order) => {
    setCurrentOrder(order);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  //skeleton
  const useStyles = makeStyles({
    customSkeleton: {
      marginTop: '1rem',
      border: '1px solid #D1D5DB',
      borderRadius: '0.5rem',
      overflow: 'hidden'
    }
  });
  const classes = useStyles();

  function OrderModal({ order, isOpen, onRequestClose }) {
    if (!order) return null;

    return (
      <div className={`fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full flex items-center justify-center ${!isOpen ? 'hidden' : ''}`}>
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Order Details</h2>
            <button onClick={onRequestClose} className="text-lg font-semibold leading-none text-gray-600 hover:text-gray-900">
              &times;
            </button>
          </div>
          <div className="space-y-2">
            <p><strong>Order ID:</strong> {order.order_id}</p>
            <p><strong>Amount:</strong> {order.order_amount}</p>
            <p><strong>Order Date:</strong> {order.order_date}</p>
            <p><strong>Name:</strong> {order.order_name}</p>
            <p><strong>Email:</strong> {order.order_email}</p>
            <p><strong>Status:</strong> {order.order_status}</p>
            <p><strong>Created Date:</strong> {order.created_date}</p>

            <hr className="border-t border-gray-300 my-4" />

            <p><strong>Code:</strong> {order.custom_1}</p>
            <p><strong>Status:</strong> {order.custom_4}</p>

          </div>
        </div>
      </div>
    );
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-screen"
      style={{
        backgroundImage: 'url("https://5ca93112550f8dcf11d2-5a9e76d0467760b267bf96203a242b5e.ssl.cf1.rackcdn.com/MM_BG.png")',
        backgroundSize: 'cover'
      }}
    >


      <div className="flex flex-col flex-grow overflow-hidden">

        <div className="home-title">
          <h1>Home</h1>
          <p>View and track recent orders from WooCommerce</p>
        </div>

        <div className="flex-grow overflow-auto bg-white rounded-lg shadow-2xl m-3">
          <div className="home-title">
            <h1>Recent Orders</h1>
          </div>


          <div className="p-4">
            {orders.map((order, index) => (

              <div key={index} className="mt-4 border border-gray-300 rounded-lg overflow-hidden">

                <table className="w-full table-fixed text-sm text-left text-gray-50 rounded-lg  ">

                  <thead className="text-xs text-gray-700 bg-gray-100 rounded-t-lg">
                    <tr>
                      <th colSpan={6}
                        className="py-3 px-6 text-sm text-[#4C7021] hover:underline cursor-pointer hover:text-[#4C7021]"
                        onClick={() => openModal(order.order)}
                      >
                        Order Number:  {order.order.order_id}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="py-4 px-6 w-1/6 text-center">
                        <p className="text-black">Customer Name:</p>
                        <p className="font-semibold text-black truncate">{order.order.order_name || "--"}</p>
                      </td>
                      <td className="w-1/6 text-center">
                        <p className="text-black">Order Date:</p>
                        <p className="font-semibold text-black"> {order.order.order_date || "--"}</p>
                      </td>
                      <td className="w-1/6 text-center">
                        <p className="text-black">Order Amount:</p>
                        <p className="font-semibold text-black truncate">{order.order.order_amount || "--"}</p>
                      </td>
                      <td className="w-1/6 text-center">
                        <p className="text-black">Phone Number:</p>
                        <p className="font-semibold text-black truncate">{order.order.phone_number || "--"}</p>
                      </td>

                      <td className="w-1/6 text-center">
                        <p className="text-black">Status:</p>
                        <p className={`font-semibold truncate ${statusColors[order.order.order_status] || "text-gray-500"}`}>{order.order.order_status}</p>
                      </td>

                      <td className="w-1/6 text-center">
                        <a
                          className="bg-moultrieYellow text-black font-semibold py-2 px-6 cursor-pointer rounded-lg hover:bg-yellow-500"
                          href={`https://radial-moultrie-mobile.pantheonsite.io/wp-admin/post.php?post=${order.order.order_id}&action=edit`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          WooCommerce
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}


            <OrderModal order={currentOrder} isOpen={modalIsOpen} onRequestClose={closeModal} />
          </div>

          {message && (
            <div className="flex flex-col items-center justify-center h-64">
              <NoDataIcon />
              <div className="text-lg font-semibold text-gray-600">
                {message}
              </div>
            </div>
          )}

          {isLoading && (
            <div className="p-4">
              <div className="mt-4 border-gray-300 rounded-lg overflow-hidden">
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton
                    sx={{ bgcolor: 'grey.350' }}
                    variant="rectangular"
                    width={max}
                    height={105}
                    className={classes.customSkeleton}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

    </motion.div>
  );
}
